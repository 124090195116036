import { create } from "zustand";



export type ConfirmationResult = "confirmed" | "rejected" | "waitingToSet";
export interface IConfirmatioModal{
    result: ConfirmationResult,
    isDisplayed: boolean;
    readonly show: (confirmationText: string) => void,
    readonly hide: (result: ConfirmationResult) => void,
    readonly clearResult: () => void,
    confirmationText: string | null
}

export const useConfirmationModal = create<IConfirmatioModal>((set, get) => ({
    isDisplayed: false,
    confirmationText: null,
    result: "waitingToSet",
    hide: (result) => {
        if(!get().isDisplayed) return;
        set(() => ({
            confirmationText: null,
            result: result,
            isDisplayed: false
        }))
    },
    show: (confirmationText) => {
        if(get().isDisplayed) return;
        set(() => ({
            result: "waitingToSet",
            confirmationText,
            isDisplayed: true
        }))
    },
    clearResult: () => (set(() => ({result: "waitingToSet"})))
}));