import { Button } from "@chakra-ui/react";
import { ICategory } from "../CategoryList/CategoriesList";
import { ISailItemActionDescription, SailItemsTable } from "./SailItemsTable";
import { useMemo } from "react";
import { RequestDisplayFunction, useProductPayment } from "../../../../../../hooks/useProductPayment";

export interface ISailItem{
    photoUrl: string,
    id: number,
    name: string;
    availableCount: number,
    price: number,
    priceString: string;
    categoryId: number;
    productDescription: string;
    productDownText: string;
}
export interface ISailItemWithCategory{
    category: ICategory,
    items: ISailItem[]
}





export function SailItems({sailItems}: {sailItems: ISailItemWithCategory[]}){
    const {requestDisplay} = useProductPayment();
    const actionDescriptions = useMemo(() => {
        const actions: ISailItemActionDescription[] = [
            {
                actionComponent: BuyNowButtonComponent(requestDisplay),
                actionName: "Buy now"
            }
        ]
        return actions;
    }, [])
    return <SailItemsTable sailItems={sailItems} actionDescriptions={actionDescriptions}/>
}


const BuyNowButtonComponent = (requestDisplay: RequestDisplayFunction ) => (sailItem: ISailItem) => {
    return <Button colorScheme="blue" onClick={() => requestDisplay({
        type: "BuyNow",
        productId: sailItem.id
    })}>Buy now</Button>
}
