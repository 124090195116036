import { Outlet, useNavigate } from "react-router-dom";
import { useIdentityStore } from "../../stores/userIdentity";
import { SailItemsCountModal } from "../../modals/SailItemsCountModal/SailItemsCountModal";
import { ConfirmationModal } from "../../modals/SailItemsCountModal/CofirmationModal";
import { PaymentTypeChooseModal } from "../../modals/SailItemsCountModal/PaymentTypeChooseModal";
import { InformationModal } from "../../modals/InformationModal";
import { BalanceTopUpModal } from "../../modals/BalanceTopUpModal";
import { ButNowProductModal } from "../../modals/BuyNowProductModal";
import { EditVoucherTextModal } from "../../modals/EditVoucherTextModal";

export function AuthenticationErrorHandler(){
    const notAuthenticatedReceived = useIdentityStore(store => store.notAuthenticatedResponseReceived);
    const logout = useIdentityStore(store => store.logout);
    const navigator = useNavigate();
    if(notAuthenticatedReceived){
        logout();
        navigator("/register");
    }
    return <> 
        <Outlet/>
                <SailItemsCountModal/>
                <ConfirmationModal/>
                <PaymentTypeChooseModal/>
                <InformationModal/>
                <BalanceTopUpModal/>
                <ButNowProductModal/>
                <EditVoucherTextModal/>
        </>
}