import { create } from "zustand";
import { clearIdenetityInfo, getIdentityInfo, persistIdentityInfo } from "../identiyManagement/UserTokenManagement";
import { UserRole } from "../identiyManagement/IdentityManagement";


type UserState<IsAuthenticated extends boolean>  ={
    isUserAuthenticated: IsAuthenticated
}

type UserAuthenticated = UserState<true> & {
    userEmail: string
}

type UserNotAuthenticated = UserState<false>;


type UserAuthState = UserAuthenticated | UserNotAuthenticated;

export type IdentityStore = {
    userRole: UserRole | null,
    userAuthState: UserAuthState,
    readonly login: (token: string, email: string) => void,
    readonly logout: () => void,
    readonly clearErrors: () => void,
    notAuthenticatedResponseReceived: boolean,
};

const getUserState = (): UserAuthState => {
    const identityInfo = getIdentityInfo();
    if(identityInfo !== null){
        return {
            isUserAuthenticated: true,
            userEmail: identityInfo.email
        }
    }
    return {
        isUserAuthenticated: false
    };
}

export const useIdentityStore = create<IdentityStore>((set, get) => {
    return {
        userRole: null,
        userAuthState: getUserState(),
        login: (token: string, email: string) => { 
            if(!get().userAuthState.isUserAuthenticated) {
                persistIdentityInfo({
                    email,
                    token
                });
            
                set(() => ({userAuthState: {
                    isUserAuthenticated: true,
                    userEmail: email
                }}))
            }
        },    
        logout: () => {
            if(get().userAuthState.isUserAuthenticated){
                clearIdenetityInfo();
                set(() => ({userAuthState: {
                    isUserAuthenticated: false,
                }}))
            }
        },
        notAuthenticatedResponseReceived: false,
        clearErrors: () => set(() => ({notAuthenticatedResponseReceived: false})) 
    }
});