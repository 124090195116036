import { Stack, Box, Button, Heading, Flex } from "@chakra-ui/react";
import { ApiResult, NoType, handleError } from "../../../../../api/types";
import { FormActions, FormDefinition, GenericForm, SelectDefinitionItem } from "../../../../Forms/GenericForm";
import { ICategory } from "../../GoodsPage/components/CategoryList/CategoriesList";
import { createSailItem, deleteSailItem, loadSailItemsWithCategoryId, loadSailItemsWithUserMessage, mapSailItem, updateSailItem } from "../../../../../sailitemmanagement/SailItemsManagement";
import { useCallback, useEffect, useMemo, useState } from "react";
import { loadCategories } from "../../../../../categoryManagement/CategoryManagement";
import { ISailItemActionDescription, SailItemsTable } from "../../GoodsPage/components/SailItems/SailItemsTable";
import { ISailItem } from "../../GoodsPage/components/SailItems/SailItems";
import { useNavigate } from "react-router-dom";
import { priceValidator } from "../../../../../shared/validators/SharedValidator";

//TODO think about file handling

export interface IProductModel{
    name: string;
    productImage: FileList,
    category: ICategory;
    price: number;
    productDescription: string;
    unitOfPrice: string;
    userUnderstendableProduct: string[];
    productDownText: string;
}

type InitialValueModel = Omit<IProductModel, "productImage" | "category"> & {
    category: number;
}

export type ResultProductModel = Omit<IProductModel, "category" | "price"> & {
    category: string;
    price: string;
} 



const defaulTextAreaWidth = "30vw";
const productModelDefinitionFactory =  (type: FormDisplayType, categories: ICategory[]): FormDefinition<IProductModel> => ({
    name: {
        label: "Name",
        type: "text",
        placeHolder: "Product name",
        rules: {
            required: true
        },
        behaviour: "single",
        width: "30vw"
    },
    category: {
        label: "Category",
        type: "select",
        mapOptionText: (category) => (category.name),
        mapValue: (category) => (category.id),
        //TODO add value substitution
        values: categories,
        rules: {
            required: true,
        },
        behaviour: "single",
        placeHolder: "Choose category",
        width: "30vw"
    },
    unitOfPrice: {
        label: "Unit of price",
        type: "text",
        rules: {},
        placeHolder: "Currency or custom text",
        behaviour: "single",
        width: "30vw"
    },
    price: {
        label: "Price",
        type: "text",
        rules: {
            required: true, 
            validate: priceValidator,
        },
        placeHolder: "Enter product price",
        behaviour: "single",
        width: "30vw"
    },
    productDescription: {
        label: "Product description",
        type: "textarea",
        behaviour: "single",
        rules: {
            required: true
        },
        width: defaulTextAreaWidth,
        placeHolder: "Enter here product description html content"
    },
    productDownText: {
        label: "Product down text",
        type: "textarea",
        behaviour: "single",
        placeHolder: "Enter here text, that will be displayed under product detailed view",
        rules:{},
        width: defaulTextAreaWidth
    },
    userUnderstendableProduct: {
        label: "Product content",
        type: "textarea",
        behaviour: "multiple",
        rules: {
            required: true,
        },
        placeHolder: "Enter here product information that will" + 
                     "be sent via email to user after payment",
        width: defaulTextAreaWidth,       
    },
    productImage: {
        label: "Product image",
        type: "file",
        behaviour: "single",
        rules: {
            required: type == "create"
        },
    },
});

function withCloseAction(close: () => void): (actions: FormActions) => FormActions {
    return (formActions) => {
        return [
            ...formActions,
            {
                text: "Close",
                color: "red",
                width: "16vw",
                action: close
            }
        ]
    }
}

const createFormActions: FormActions = [
    {
        text: "Create",
        color: "green",
        isSubmit: true,
        width: "16vw"
    },
]
const editFormActions: FormActions = [
    {
        text: "Update",
        color: "green",
        isSubmit: true,
        width: "16vw"
    },
];

const ProductManageForm = ({
        initialValue,
        submitAction, 
        afterSubmitAction, 
        formActions,
        formDefinition}: 
    {  
        initialValue?: InitialValueModel, 
        submitAction: (model: ResultProductModel) => Promise<ApiResult>, 
        formActions: FormActions,
        afterSubmitAction?: () => void, 
        formDefinition: FormDefinition<IProductModel>
        }) =>{
    return <Box width="100%">{GenericForm<IProductModel, NoType, ResultProductModel, InitialValueModel>({
        formDefinition,
        formActions: formActions,
        initialValue,
        direction: "column",
        submitAction,
        afterSubmitAction: afterSubmitAction ?? (() => {})
    })}</Box>;
}

type FormDisplayType = "create" | "edit";
function SailItemForm({value:sailItem, close, afterSucessfullSubmit, type}: {
    value?: ISailItem, close:() => void, afterSucessfullSubmit?: () => void, type: FormDisplayType}){
    if(!sailItem && type == "edit"){
        throw new Error("Invalid app flow");
    }


    const [initialFormValueForSailItemToEdit, setInitialValue] = useState<InitialValueModel | undefined>(undefined);
    const [categories, setCategories] = useState<ICategory[] | null> (null);
    const makeErrorExtendedActions = useMemo(() => withCloseAction(close), [close])
    const navigate = useNavigate();

    useEffect(() => {
        loadCategories()
            .then(requestResult =>{
            if(requestResult.success){
                setCategories(requestResult.data.filter(category => category.id !== -1));
            }
        } )
    }, []);

    useEffect(() => {
        if(type == "edit"){
            loadSailItemsWithUserMessage(sailItem!.id).then(res => {
                console.log(res);
                if(res.success){            
                    const fetchData = res.data;
                
                    const initialValue: InitialValueModel = {
                        productDownText: fetchData.productDownText,
                        name: fetchData.name,
                        category: fetchData.categoryId,
                        price: fetchData.price,
                        productDescription: fetchData.productDescription,
                        unitOfPrice: fetchData.currency,
                        userUnderstendableProduct: fetchData.userMessages,
                    };
                    setInitialValue(initialValue);
                }else
                {
                    //TODO maybe concurrency issue and sail item already delted
                    navigate("/admin");
                }
            })
        }
        else{
            setInitialValue(undefined);
        }
    } , [type])

    const formActions = useMemo(() => {
        return type == "create"?  makeErrorExtendedActions(createFormActions)
                : makeErrorExtendedActions(editFormActions)
    }, [type]);


    const submitAction = type == "create"? createSailItem: (model: ResultProductModel) => {
        console.log(model);
        return updateSailItem({
        ...model,
        id: sailItem!.id
    })};
    const formText = type == "create"? "Create product": "Edit product";

    if(categories !== null && (type == "create" || !!initialFormValueForSailItemToEdit)){
        return <Box paddingInline="10vw"> 
                <Stack alignItems="center" padding="2vw">
                    <Heading marginBottom="0.5em">{formText}</Heading>
                    <ProductManageForm 
                        submitAction={submitAction}
                        initialValue={initialFormValueForSailItemToEdit} 
                        formActions={formActions} 
                        afterSubmitAction={afterSucessfullSubmit} 
                        formDefinition={productModelDefinitionFactory(type, categories)}/>
                </Stack>
            </Box>
    }
    return <h1>Loading categories</h1>;
}


const editSailItemActionDefinitionFabric = (editSailItem: (sailItem: ISailItem) => void): ISailItemActionDescription => ({
    actionName: "Edit",
    actionComponent: (sailItem) => <Button colorScheme="blue" onClick={() => editSailItem(sailItem)}>Edit</Button>
});

const deleteSailItemActionDefinitionFabric = (deleteSailItem: (sailItem: ISailItem) => void): ISailItemActionDescription => ({
    actionName: "Delete",
    actionComponent: (sailItem) => <Button colorScheme="red" onClick={() => deleteSailItem(sailItem)}>Delete</Button>
});

type DisplayState = EditDisplayState | CreateDisplayState | NoneDisplayState;

type EditDisplayState = {
    state: "Edit",
    sailItemToEdit: ISailItem
}

type CreateDisplayState = {
    state: "Create";
}

type NoneDisplayState = {
    state: "None";
}
//Just to not overwhelm GC
const NoneDisplayStateConst: NoneDisplayState = {
    state: "None"
};

const CreateDisplayStateConst: CreateDisplayState ={
    state: "Create"
};


export function Products(){
    const [sailItems, setSailItems] = useState<ISailItem[] | null>(null);
    const [displayState, setDisplayState] = useState<DisplayState>(NoneDisplayStateConst);

    const deleteSailItemAction = useCallback((sailItem: ISailItem) => {
        //refresh after deletion in case if items content reloaded
        deleteSailItem(sailItem.id)
            .then((res) => {
                if(res.success){
                    updateSailItems();
                }
            });
    }, []);


    const editSailItemAction = useCallback((sailItem: ISailItem) => {
        setDisplayState({
            "state": "Edit",
            sailItemToEdit: sailItem
        });
    }, [])

    const actionDescriptions: ISailItemActionDescription[] = useMemo(() => {
        const def1 = editSailItemActionDefinitionFabric(editSailItemAction);
        const def2 = deleteSailItemActionDefinitionFabric(deleteSailItemAction);
        return [def1, def2];
    }, [deleteSailItemAction, editSailItemAction]);

    const sailItemToEdit: ISailItem | undefined = useMemo(() => {
        if(displayState.state == "Edit"){
            return displayState.sailItemToEdit;
        }
        return undefined;
    }, [displayState]);

    const updateSailItems = useCallback(() => {
        loadSailItemsWithCategoryId(-1).then(sailItems => {
            if(sailItems.success){
                setSailItems(sailItems.data.map(mapSailItem));
            }
        });
    }, []);
    useEffect(() => {
        updateSailItems();
    },[]);

    const handleCloseWithReload = useCallback(() => {
        setDisplayState(NoneDisplayStateConst);
        updateSailItems();
    }, []);

    if(sailItems == null){
        return <h1>Loading products</h1>
    }

    return <Stack spacing="2em">
        {displayState.state =="Edit" && <Box marginBottom="1em">
                                            <SailItemForm value={sailItemToEdit} 
                                            close={() => setDisplayState(NoneDisplayStateConst)}
                                            type="edit" 
                                            afterSucessfullSubmit={handleCloseWithReload}/>
                                        </Box>}

        {displayState.state == "Create" && <Box marginBottom="1em">
                                                <SailItemForm 
                                                    close={() => setDisplayState(NoneDisplayStateConst)} 
                                                    afterSucessfullSubmit={handleCloseWithReload}
                                                    type="create"/>
                                           </Box>}
        
        <SailItemsTable sailItems={sailItems} actionDescriptions={actionDescriptions}/>
        <Button alignSelf="center" onClick={() => setDisplayState(CreateDisplayStateConst)} width="fit-content" colorScheme="green">Create new product</Button>
        </Stack>
}
