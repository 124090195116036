import {
    Modal,
    Text,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
    useDisclosure,
    FormControl,
    Input,
    FormErrorMessage,
    FormLabel,
    Flex,
    HStack,
    Button
} from "@chakra-ui/react";

import {
    useCallback,
    useEffect,
    useState
} from "react";
import {ISailItem} from "../components/Main/components/GoodsPage/components/SailItems/SailItems";
import {
    loadSailItemWithId,
    mapSailItem
} from "../sailitemmanagement/SailItemsManagement";
import {SailItemImage} from "../components/Main/components/GoodsPage/components/SailItems/SailIttemImage";
import {IAnononymusPaymentResult, useBuyNowModalStore} from "../stores/buyNowModalStore";
import {useForm} from "react-hook-form";
import {SailItemCount} from "../components/Main/components/GoodsPage/components/SailItems/SailItemCount";
import {VoucherFormControl, voucherGenericValidation} from "./SailItemsCountModal/VoucherFormControl";
import {applyVoucher, getValidationStateGeneric} from "./SailItemsCountModal/PaymentTypeChooseModal";
import {createAnonymusPayment} from "../paymentManagement/PaymentManagement";

import {Audio, BallTriangle} from "react-loader-spinner";
import {outlineLightGray} from "../shared/styles/content-outline";
import {stringifyPrice} from "../shared/PriceManagement";
import {useAuthorization} from "../identiyManagement/hooks";
import SelectMerchant from "../components/Main/components/Cart/SelectMerchant";


export interface IBuyNowModel {
    email: string;
    voucher: string;
    quantity: number;
    productId: number
}

type BuyState = "rejected" | "confirmed" | "requestedAddToCart";

type SailItemWithPrice = ISailItem & {
    totalPrice: number;
    priceAfterVoucherApplication: number;
}

type VoucherMeta = {
    currency: "$" | "%";
    amountToApply: number;
}


export type PaymentState = "pending" | "completed";

export function ButNowProductModal() {
    const {
        formState: {errors},
        getValues,
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        reset
    } = useForm<{
        email?: string;
        quantity: number;
        voucher: string;
        merchant: string;
    }>();
    const {isAuthenticated, email} = useAuthorization();
    const [voucherMeta, setVoucherMeta] = useState<VoucherMeta | null>(null);
    const [sailItem, setSailItem] = useState<SailItemWithPrice | null>(null);
    const [paymentErrors, setPaymentErrrors] = useState<string | null>(null);
    const [paymentState, setPaymentState] = useState<PaymentState>("completed");
    const [merchant, setMerchant] = useState('plisio');
    const isDisplayRequested = useBuyNowModalStore(state => state.isDisplayed);
    const sailItemToBuyId = useBuyNowModalStore(state => state.requestedProductId);
    const hideModalAndSetState = useBuyNowModalStore(state => state.hide);
    const {isOpen, onOpen, onClose} = useDisclosure();

    const submitAction = () => {
        setPaymentErrrors(null);
        handleClose("confirmed");
    };


    const finalize = () => {
        reset();
        setPaymentState("completed");
        setPaymentErrrors(null);
        setSailItem(null);
        setVoucherMeta(null);
        onClose()
    };
    const handleClose = (resultState: BuyState) => {
        if (resultState == "rejected") {
            hideModalAndSetState({
                state: "rejected"
            });
            finalize();
        } else if (resultState == "requestedAddToCart") {
            hideModalAndSetState({
                state: "requestedAddToCart"
            });
            finalize();
        } else {
            const formValues = getValues();
            setPaymentState("pending");
            if (formValues.quantity == 0) {
                handleClose("rejected");
                return;
            }
            const userEmail = (isAuthenticated ? email : formValues.email)!;
            createAnonymusPayment({
                productAmount: formValues.quantity,
                voucher: formValues.voucher == "" ? null : formValues.voucher,
                merchant: merchant,
                productId: sailItem!.id!,
                requestedEmail: userEmail
            }).then(res => {
                if (res.success) {
                    hideModalAndSetState({
                        model: {
                            email: res.data.email,
                            password: res.data.password,
                            paymentUrl: res.data.paymentUrl
                        },
                        state: "succeedPayment"
                    });
                    finalize();
                } else {
                    const errorModel = res.data;
                    console.log(errorModel);
                    if (errorModel.failedIds.length > 0) {
                        hideModalAndSetState({
                            state: "failToBuyProduct",
                        });
                        finalize();
                        return;
                    }
                    setPaymentErrrors(errorModel.reason);
                }
            });
        }
    }

    if (isDisplayRequested && !isOpen) {
        onOpen();
    }
    if (!isDisplayRequested && isOpen) {
        handleClose("rejected");
    }
    useEffect(() => {
        if (sailItemToBuyId) {
            loadSailItemWithId(sailItemToBuyId).then(res => {
                if (res.success) {
                    const resultSailItem = res.data;
                    const mappedSailItem = mapSailItem(resultSailItem);
                    const targetItemsQuantity = mappedSailItem.availableCount > 0 ? 1 : 0;
                    const computedPrice = targetItemsQuantity * mappedSailItem.price;
                    const sailItemWihtPrice: SailItemWithPrice = {
                        ...mappedSailItem,
                        totalPrice: computedPrice,
                        priceAfterVoucherApplication: computedPrice
                    };
                    setSailItem(sailItemWihtPrice);
                    setValue('quantity', targetItemsQuantity);
                } else {
                    handleClose("rejected");
                }
            })
        }
    }, [sailItemToBuyId, getValues]);

    const handleItemsCountUpdated = useCallback((newAmount: number) => {
        if (!sailItem) throw new Error("Invalid flow. You couldn't update count while product not loaded");
        setValue("quantity", newAmount);
        const newTotalPrice = newAmount * sailItem.price
        const updatedSailItem: SailItemWithPrice = {
            ...sailItem,
            totalPrice: newTotalPrice,
            priceAfterVoucherApplication: newTotalPrice
        }
        console.log(newTotalPrice);

        const sailItemToChange = !!voucherMeta ? applyVoucherToSailItem(updatedSailItem, voucherMeta)
            : updatedSailItem;
        setSailItem(sailItemToChange)
    }, [sailItem])


    const applyVoucherToSailItem = (sailItem: SailItemWithPrice, voucherMeta: VoucherMeta) => {
        if (!sailItem) throw Error("Invalid flow. You can't apply voucher that wasn't loaded");
        const resultPrice = sailItem!.totalPrice;
        const priceAfterVoucherApplication = applyVoucher(voucherMeta.currency, voucherMeta.amountToApply, resultPrice);
        const resultSailItem: SailItemWithPrice = {
            ...sailItem!,
            priceAfterVoucherApplication: priceAfterVoucherApplication
        };
        return resultSailItem;
    }


    const runInternalVoucherValidation = useCallback(() => {
        clearErrors("voucher");
        const voucherFieldValue = getValues()['voucher'];
        return voucherGenericValidation(voucherFieldValue, (errorMessage) => {
            setError("voucher", {message: errorMessage});
        }, (voucherModel) => {
            const voucherMeta: VoucherMeta = {
                amountToApply: voucherModel.discount!,
                currency: voucherModel.currency!
            };
            const resultSailItem = applyVoucherToSailItem(sailItem!, voucherMeta);
            setSailItem(resultSailItem);
            setVoucherMeta(voucherMeta);
        })
    }, [sailItem, getValues]);
    const SailItemContent = () => {
        if (!sailItem || paymentState == "pending") {
            return <Flex justifyContent="center" padding="2em">
                <BallTriangle
                    height="80"
                    width="80"
                    color={outlineLightGray}
                    ariaLabel="loading"/>
            </Flex>
        }
        ;

        return <VStack paddingTop="1em" alignItems="center" spacing="2em">
            <SailItemImage sailItem={{
                name: sailItem.name,
                photoUrl: sailItem.photoUrl
            }}/>

            <form onSubmit={handleSubmit(submitAction)}>
                <VStack spacing="1em">
                    {!isAuthenticated && <FormControl isInvalid={!!errors['email']} isRequired={true}>
                        <Flex alignItems="center">
                            <FormLabel>
                                Email
                            </FormLabel>
                            <Input {...register('email', {
                                required: true,
                            })} type="email"/>
                        </Flex>
                        {!!errors['email'] && <FormErrorMessage>
                            {errors['email'].message}
                        </FormErrorMessage>}
                    </FormControl>}
                    <SailItemCount showText={true} count={getValues().quantity}
                                   minValue={sailItem.availableCount == 0 ? 0 : 1}
                                   maxValue={sailItem.availableCount}
                                   updateCount={handleItemsCountUpdated}
                    />
                    <VoucherFormControl registrationData={register("voucher")}
                                        getValidationState={() => getValidationStateGeneric("voucher", errors)}
                                        runVoucherValidation={runInternalVoucherValidation}/>

                    <VStack spacing="0.5em">
                        <Text fontSize="1.2em">Total price: {stringifyPrice(sailItem.totalPrice.toFixed(2))}</Text>
                        {sailItem.totalPrice !== sailItem.priceAfterVoucherApplication &&
                            <Text fontSize="1.2em">Price after voucher
                                application: {stringifyPrice(sailItem.priceAfterVoucherApplication)}</Text>}
                    </VStack>
                    <Text
                        fontSize="1.4em"
                        whiteSpace="pre-wrap"
                        textAlign="center"
                        color="red.300">{paymentErrors}</Text>
                    <SelectMerchant setMerchant={setMerchant}/>
                    <HStack spacing="1em">
                        <Button colorScheme="green" type="submit">Buy</Button>
                        <Button colorScheme="blue" onClick={() => handleClose("requestedAddToCart")}>Add to
                            Cart</Button>
                    </HStack>

                </VStack>
            </form>
        </VStack>
    };
    return <Modal isOpen={isOpen}
                  onClose={() => handleClose("rejected")}
                  size="xl"
                  isCentered>
        <ModalOverlay>
            <ModalContent>
                <ModalBody>
                    <SailItemContent/>
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    </Modal>
}