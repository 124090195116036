import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Stack } from "@chakra-ui/react";
import { IVoucherCheckReponseModel, validateVoucher } from "../../vouchermanagement/VoucherManagement";
import { BaseFormControlProps } from "./PaymentTypeChooseModal";

export const voucherGenericValidation = (
     voucherValue: string,
     onVoucherInvalid: (errorMessage: string) => void,
     onVoucherValid: (voucherModel: IVoucherCheckReponseModel) => void) => {
        if(voucherValue == ""){
            onVoucherInvalid("Coupon could not be empty");
            return Promise.resolve();
        }
    
        return validateVoucher({
            voucher: voucherValue
        }).then((voucherValidationResponse) => {
            if(voucherValidationResponse.success){
                const data = voucherValidationResponse.data;
                if(!data.isValid){
                    onVoucherInvalid(data.reason);
                }else{
                    onVoucherValid(data);
                }
            }
        });
}

type VoucherFormControlProps = BaseFormControlProps & {
    runVoucherValidation: () => Promise<void>
}

export const VoucherFormControl = ({
                             registrationData, 
                             getValidationState,
                             runVoucherValidation}: VoucherFormControlProps) => {
    const voucherState = getValidationState();
    return <FormControl isInvalid={voucherState.status == "invalid"}>
    <Stack spacing="1em">
        <Flex alignItems="center">
            <FormLabel marginRight="1em" fontSize="1.2em">Coupon</FormLabel>
            <Input marginRight="1em" width="55%" {...registrationData} type="text" placeholder="Enter coupon"/>   
            <Button width="fit-content" colorScheme="blue" onClick={runVoucherValidation}>Redeem</Button>
        </Flex>
        {voucherState.status == "invalid" && <FormErrorMessage>
            {voucherState.errorMessage}
        </FormErrorMessage>}
    </Stack>
</FormControl>;
}
