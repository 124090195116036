import { create } from "zustand";

export type PaymentType = "wallet" | "balance"

export type PaymentSelectionResult = {
    voucher?: string,
    paymentType: PaymentType;
    merchant?: string;
}

export type PaymentRejected = {rejected: true };

export const paymentRejectedConst: PaymentRejected = {rejected: true}

export type None = unknown;

export const noneConst: None = {};

export function isPaymentSelectionResult(result:PaymentSelectionResult | PaymentRejected | None): result is PaymentSelectionResult{
    const castedResult = result as PaymentSelectionResult;
    return !!castedResult.paymentType && ["balance", "wallet"].includes(castedResult.paymentType);
}

export interface IPaymentSelectionTypeModalModel{
    isDisplayed: boolean;
    estimatedPrice: number | null;
    result: PaymentSelectionResult | PaymentRejected | None,
    readonly show: (estimatedPrice: number) => void;
    readonly hide: (result: PaymentRejected | PaymentSelectionResult) => void;
    clearResult: () => void;
}


export const usePaymentTypeChooseModal = create<IPaymentSelectionTypeModalModel>((set, get) => (
    {
        clearResult: () => (set(() => ({
            result: noneConst
        }))),
        isDisplayed: false,
        result: noneConst,
        hide: (result: PaymentRejected | PaymentSelectionResult) => {
            if(!get().isDisplayed) return;
            set(() => ({
                isDisplayed: false,
                result,
                estimatedPrice: null
            }));
        },
        show: (estimatedPrice: number) => {
            if(get().isDisplayed) return;
            set(() => ({
                isDisplayed: true,
                estimatedPrice
            }));
        },
        estimatedPrice: null
    }
));