import {create} from "zustand";


export interface IAnononymusPaymentResult {
    email: string | null;
    password: string | null;
    paymentUrl: string;
}

type ButNowResult = {
    state: "rejected"
} | {
    state: "succeedPayment",
    model: IAnononymusPaymentResult; 
} | {
    state: "requestedAddToCart"
} | {
    state: "failToBuyProduct"
}

export interface IButNowModalStore{
    isDisplayed: boolean;
    result: ButNowResult | null;
    requestedProductId: number | null;
    show: (requestedProductId: number) => void;
    hide: (result: ButNowResult) => void;
    clearResult: () => void;
}

export const useBuyNowModalStore = create<IButNowModalStore>((set, get) => {
    return {
        isDisplayed: false,
        result: null,
        requestedProductId: null,
        show: (productId:number) => {
            if(get().isDisplayed) return;
            set(() => ({isDisplayed: true, requestedProductId: productId}));
        },
        hide: (buyNowResult) => {
            if(!get().isDisplayed) return;
            set(() => ({
                isDisplayed: false,
                requestedProductId: null,
                result: buyNowResult
            }));
        },
        clearResult: () => {
            set(() => ({result: null}));
        }
    }
});

