
const tokenKey = "identityInfo";


export interface IIdentityInfo{
    token: string;
    email: string;
}

export function persistIdentityInfo(token: IIdentityInfo){
    const parsedData = JSON.stringify(token);
    localStorage.setItem(tokenKey, parsedData);
}

export function clearIdenetityInfo(){
    localStorage.removeItem(tokenKey);
}

export function getIdentityInfo(): IIdentityInfo | null{
    const identityInfoStringified = localStorage.getItem(tokenKey);
    if(identityInfoStringified)
    {
        const parsedIdentityInfo = JSON.parse(identityInfoStringified) as IIdentityInfo;
        return parsedIdentityInfo;
    }
    return null;
}