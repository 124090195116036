import { Flex, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, ModalFooter, Button, useDisclosure, Input, NumberInput, NumberInputField, NumberDecrementStepper, NumberIncrementStepper, NumberInputStepper, FormControl, FormLabel } from "@chakra-ui/react";
import { useItemsCountModalStore } from "../../stores/itemscount"
import { useEffect, useState } from "react";
import { loadSailItemWithId, mapSailItem } from "../../sailitemmanagement/SailItemsManagement";
import { ISailItem } from "../../components/Main/components/GoodsPage/components/SailItems/SailItems";
import { SailItemImage } from "../../components/Main/components/GoodsPage/components/SailItems/SailIttemImage";
import { SailItemCount } from "../../components/Main/components/GoodsPage/components/SailItems/SailItemCount";
import { GetItemCount } from "../../cartmanagement/CartManagement";
import { stringifyPrice } from "../../shared/PriceManagement";

export function SailItemsCountModal(){
    const [countOfItems, setCount] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [sailItem, setSailItem] = useState<ISailItem | null>(null);
    const isRequestedDisplay = useItemsCountModalStore((state) => state.isDisplayed);
    const hide = useItemsCountModalStore((state) => state.hide);
    const sailItemId = useItemsCountModalStore((state) => state.sailItemId);
    useEffect(() => {
        if(sailItemId && isRequestedDisplay){
            loadSailItemWithId(sailItemId!)
                .then(loadedItem => {
                    if(loadedItem.success){
                        const mappedItem = mapSailItem(loadedItem.data);
                        setSailItem(mappedItem);
                        mappedItem.availableCount > 0? setCount(1) : setCount(0);
                    }
                });
        }
    }, [sailItemId, isRequestedDisplay])

    if(!isRequestedDisplay && isOpen){
        onClose();
    }
    if(!isOpen && isRequestedDisplay){
        onOpen();
    }

    const updateCount = (newCount: number) => {
        setCount(newCount);
    }

    const onBuy = () => {
        hide(countOfItems);
        setCount(0);
    };
    const onDiscard = () => {
        setCount(0);
        hide();
    };
    return <>{!!sailItem && <Modal isOpen={isOpen} size="lg" onClose={onDiscard} isCentered>
                <ModalOverlay/>
                <ModalContent>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Stack paddingTop="1em" spacing="2.5em">
                            <Flex flexDirection="row" width="100%" alignItems="center">
                                <SailItemImage sailItem={sailItem!}/>
                                <Text marginLeft="0.4em" whiteSpace="nowrap">{stringifyPrice(sailItem?.price)}</Text>
                            </Flex>
                            <SailItemCount 
                                minValue={sailItem.availableCount > 0? 1 : 0} 
                                count={countOfItems} updateCount={updateCount} 
                                showText={true} 
                                maxValue={sailItem.availableCount - (GetItemCount(sailItem.id) ?? 0)}/>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={onBuy}>Buy</Button>
                    </ModalFooter>
                </ModalContent>
           </Modal>
    }
    </>;
}
