import { useCallback, useEffect, useState } from "react";

export function useResizeOnChange<TDisplayType>(project: (width: number) => TDisplayType ){
    const [displayMode, setDisplayMode] = useState<TDisplayType>(project(window.innerWidth));

    const resizeHandler = useCallback(() => {
        const currentWidth = window.innerWidth;
        const nextDisplayMode = project(currentWidth);
        if(nextDisplayMode !== displayMode){
            setDisplayMode(nextDisplayMode);
        }
    }, [displayMode]);

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        
        return () => window.removeEventListener("resize", resizeHandler);
    }, [displayMode]);

    return displayMode;
}

