import { axiosInstance } from "../api/axios"
import { ApiResult, ApiSuccessResult, handleError } from "../api/types";

export interface IBalanceResponseModel{
    balance: number;
}

export function loadBalance(): Promise<ApiResult<IBalanceResponseModel>>{
    return axiosInstance.get("/Balance").then((resp) => {
        return {
            success: true,
            data: resp.data as IBalanceResponseModel
        } as ApiResult<IBalanceResponseModel>
    });
}


export interface IBalanceUpdateRequestModel{
    amount: number;
    merchant: string;
}

export interface IBalanceUpdateResponseModel{
    paymentUrl: string;
}
export function updateBalance(model: IBalanceUpdateRequestModel): Promise<ApiResult<IBalanceUpdateResponseModel>>{
    return handleError(axiosInstance.post("/Balance", model).then(res =>{
        return {
            data: res.data as IBalanceUpdateResponseModel,
            success: true
        } as ApiSuccessResult<IBalanceUpdateResponseModel>
    }));
}