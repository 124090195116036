
const sailItemKey = "cart_sailItems";


export type ItemIdToItemsToBuyCount = {
    [itemId: number]: number
}
export interface SailItemCart {
    sailItems: ItemIdToItemsToBuyCount
}

export function UpdateCartSailItemCount(saleItemId: number, newCount: number){
    const content = GetCartContnet();
    if(!content.sailItems[saleItemId]){
        AddItemToCart(saleItemId, newCount);
    }else{
        const cartEntries: SailItemCart = JSON.parse(localStorage.getItem(sailItemKey)!);
        const newItems = {
            ...cartEntries,
            sailItems: {
                ...cartEntries.sailItems,
                [saleItemId]: newCount
            }
        };
        const newItemsString = JSON.stringify(newItems);
        localStorage.setItem(sailItemKey, newItemsString);
    }
}

export function AddItemToCart(saiilItemId: number, countOfItems: number = 1){
    const sailItemCartString = localStorage.getItem(sailItemKey);
    let resultCart: SailItemCart | null = null
    if(!sailItemCartString){
        const initialCart: SailItemCart = {
            sailItems: {
                [saiilItemId]: countOfItems
            }
        };
        resultCart = initialCart;
    }else{
        const persistedCart = JSON.parse(sailItemCartString) as SailItemCart;
        const newSailItems = {...persistedCart.sailItems, [saiilItemId]: 
            (persistedCart.sailItems[saiilItemId] ?? 0) + countOfItems};
        resultCart = {
            sailItems: newSailItems
        };
    }

    console.log("In next line result cart will be");
    console.log(resultCart);

    PersistSailItems(resultCart);
}

export function RemoveItemFromCart(id: number){
    const cartContent = GetCartContnet();
    const resultObject = Object.fromEntries(Object.entries(cartContent.sailItems)
        .filter(([sailItemId, sailItem]) => parseInt(sailItemId) !== id));
    const cart: SailItemCart = {
        sailItems: resultObject
    }
    PersistSailItems(cart);
}

function PersistSailItems(cart: SailItemCart){
    const resultCartString = JSON.stringify(cart);
    localStorage.setItem(sailItemKey, resultCartString);
}
export function GetCartContnet(): SailItemCart{
    const parsedCart = localStorage.getItem(sailItemKey);
    if(!parsedCart){
        return {
            sailItems: {}
        }
    }    
    return JSON.parse(parsedCart) as SailItemCart;
}

export function GetItemCount(sailItemId: number): number | null{
    return GetCartContnet().sailItems[sailItemId] ?? null;
}