import { createBrowserRouter, createRoutesFromChildren, Navigate, Route, RouterProvider, Routes, useLocation } from "react-router-dom";
import { Main } from "./components/Main/Main";
import { Register } from "./components/Forms/Register";
import { Login } from "./components/Forms/Login";
import { Fallback } from "./components/Fallback/Fallback";
import { Purchases } from "./components/Main/components/Purchases/Purchases";
import { Goods, goodsLoader } from "./components/Main/components/GoodsPage/Goods";
import { Cart } from "./components/Main/components/Cart/Cart";
import { AuthenticationErrorHandler } from "./components/AuthenticationErrorHandler/AuthenticationErrorHandler";
import { Admin } from "./components/Main/components/Admin/Admin";
import { EditableCategories } from "./components/Main/components/Admin/components/EditableCategories";
import { Products } from "./components/Main/components/Admin/components/Products";
import { Voucher } from "./components/Main/components/Admin/components/Voucher";
import { SiteDisplay } from "./components/Main/components/Admin/components/SiteDisplay";
import { createDetailedProductPath, DetailedProduct } from "./components/Main/components/DetailProduct/DatailedProduct";
import { RestorePassowrd } from "./components/PasswordRestore/RestorePassword";
import MerchantManagementForm from "./admin/MerchantManagment";


const router = createBrowserRouter(
    [{
       path: "/",
       element: <AuthenticationErrorHandler/>,
       errorElement: <Fallback/>,
       children: [
        { 
            element: <Main/>,
            children: [
                //In that exact case 1 will mean that it's default category id for all categories
                { index: true, loader: goodsLoader, element: <Goods/>},
                { path: createDetailedProductPath(), element: <DetailedProduct/>},
                { path: "payments", element: <Purchases/>},
                { path: "/cart", element: <Cart/> },
                { 
                    path:"/admin",
                    children: [
                        { index: true, element: <Navigate to="categories"/> },
                        { path: "categories", element: <EditableCategories/> },
                        { path: "products", element: <Products/> },
                        { path: "vouchers", element: <Voucher/> },
                        { path: "displayOptions", element: <SiteDisplay/> },
                        { path: 'merchants', element: <MerchantManagementForm/>}
                    ],
                element: <Admin/>}
            ] },
        { path:"/register", element: <Register/>},
        { path:"/login", element: <Login/>},
        { path: "/restorePassword", element: <RestorePassowrd/>},
       ]
    }
    ]
)

export {router as Router}