import React, {useEffect, useState} from "react";
import {Button, Heading, HStack, Input, Stack, Switch, Text} from "@chakra-ui/react";
import {useQuery} from "react-query";
import {GetAllMerchants, UpdateMerchant} from "../services/Merchant/MerchantService";
import {SailItemsTable} from "../components/Main/components/GoodsPage/components/SailItems/SailItemsTable";
import {Merchant} from "../services/Merchant/types";

type MerchantRequest = {
    merchants: Merchant[];
}

export default function MerchantManagementForm() {
    const [request, setRequest] = useState<MerchantRequest>({
        merchants: []
    });
    const {isLoading, isError, data, error} = useQuery('merchants', GetAllMerchants);
    useEffect(() => {
        if (data?.data.result.merchants) {
            setRequest({merchants: data.data.result.merchants});
        }
    }, [data]);

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedMerchants = [...request.merchants];
        updatedMerchants[index].visibleName = event.target.value;
        setRequest({merchants: updatedMerchants});
    };

    const handleSwitchChange = (index: number) => {
        const updatedMerchants = [...request.merchants];
        updatedMerchants[index].active = !updatedMerchants[index].active;
        setRequest({merchants: updatedMerchants});
    }

    const handleUpdateMerchant = async (index: number) => {
        const merchantToUpdate = request.merchants[index];

        const response = await UpdateMerchant(merchantToUpdate);

        console.log(response);
    };

    if (isLoading) {
        return <>Is loading...</>
    }

    return (
        <>
            <Stack spacing="3.5em" alignItems="center">
                <Heading margin="2em auto 0 auto">Merchants</Heading>
                <form style={{display: "flex", gap: '20px'}}>
                    {data?.data.result.merchants.map((el, index) => (
                        <Stack spacing="1em" key={el.defaultName}>
                            <Text fontSize="md">{el.defaultName}</Text>
                            <Input
                                placeholder="Seen name"
                                value={el.visibleName}
                                onChange={(event) => handleInputChange(index, event)}
                            />
                            <Switch isChecked={el.active} onChange={() => handleSwitchChange(index)}/>
                            <Button onClick={() => handleUpdateMerchant(index)}>Update</Button>
                        </Stack>
                    ))}
                </form>
            </Stack>
        </>
    )
}