import { Flex, Image, ResponsiveValue, Text } from "@chakra-ui/react"
import { ISailItem } from "./SailItems"
import { useCallback, useEffect } from "react";
import { useResizeOnChange } from "../../../../../../hooks/useResizeOnChange";



export interface IProductModel {
    photoUrl: string;
    name: string;
}
export type SailItemProps = {
    handleHower?: boolean;
    sailItem: IProductModel;
    sizingStrategy?: SizingStrategy;
    onLabelClick?: () => void;
}


type DisplayState = "wrapped" | "flat";
export type SizingStrategy = "fit-content" | "fill-space"
export function SailItemImage({sailItem, handleHower, sizingStrategy, onLabelClick}: SailItemProps){
    const mappedStrategy = sizingStrategy ?? "fill-space";
    const mappedHower = handleHower == undefined? true: handleHower;
    const defaultHandler = useCallback(() => {}, []);
    const textClickHandler = onLabelClick ?? defaultHandler;
    const displayState = useResizeOnChange<DisplayState>((width) => {
        if(width < 540){
            return "wrapped";
        }
        return "flat"
    });


    const containerStyles = {
        wrap: displayState == "wrapped"? "wrap": "unset" as ResponsiveValue<any>,
        justifyContent: displayState == "wrapped"? "center": "unset"
    };

    const imageStyles = {
        mb: displayState == "wrapped"? "0.5em" : "unset"
    };

    const textStyles = {
        ml: displayState == "wrapped"? "unset": "1.2em",
        _hover: mappedHower? {fontWeight: "500", textDecoration: "underline"} : undefined
    };

    return <Flex  {...containerStyles} width={mappedStrategy == "fill-space"? "100%" : "fit-content"} flexWrap={window.innerWidth < 530? "wrap" : "nowrap" }  alignItems="center">
        <Image  {...imageStyles} height="80px" src={sailItem.photoUrl} alt="Here should be item photo"/>
        <Text {...textStyles} onClick={textClickHandler} width="fit-content" {...textStyles}>{sailItem.name}</Text>
    </Flex>
}