import axios from "axios";
import { getConfiguration } from "../configuration/Configuration";
import { getIdentityInfo } from "../identiyManagement/UserTokenManagement";
import { useIdentityStore } from "../stores/userIdentity";

const axiosInstance = axios.create({
    baseURL: getConfiguration().apiBaseAddress,
    timeout: 200000,
    headers: {
        "Accept": "application/json"
    }
});


axiosInstance.interceptors.request.use((config) =>{
    const identityInfo = getIdentityInfo();
    if(identityInfo){
        config.headers['Authorization'] = `Bearer ${identityInfo.token}`
    }
    return config;
})

axiosInstance.interceptors.response.use((succ) => (succ), (error) => {
    if(error.response && error.status === 401){
        useIdentityStore.setState({
            notAuthenticatedResponseReceived: true
        })
    }
    return Promise.reject(error);;
})



export {axiosInstance}