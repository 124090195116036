import {axiosInstance} from "../../api/axios";
import {Merchant} from "./types";

interface DefaultResult {
    isSuccess: boolean;
    error: string[] | null;
}

interface MerchantResult extends  DefaultResult{
    result: {
        merchants: Merchant[]
    };
}

export const GetAllMerchants = async () => {
    const response  = await axiosInstance.get('/merchant').then(res =>{
    return {
        data: res.data as MerchantResult
    }});
    return response;
}

export const UpdateMerchant = async (merchant: Merchant) => {
    try {
        const response = await axiosInstance.patch('/merchant', merchant);
        return response;
    }
    catch (e) {
        console.log(e);
    }
}