import { Box, Flex } from "@chakra-ui/react"
import { RenderedLink } from "../Header"

export const LinksFlatList = ({links}:{links: RenderedLink[]}) => {
    return <ul>
    <Flex listStyleType="none" align="center" justifyContent="space-between">
        {links.map((link, index, arr) => (<Box marginRight={index == arr.length -1? 0: "1.2em"} 
            key={index} whiteSpace="nowrap" fontSize="1.2em" borderBottom="0.5px solid transparent" transition="0.3s" padding="0.5em 0"_hover={{"borderColor": "#fbb11a"}}>
                                        <li key={index} onClick={link.clickHandler}>{link.content}</li>
                                    </Box>))}
    </Flex>
</ul>
}
