
import {Button, Flex, FormControl, FormLabel, Text, Input, Stack, Box, FormErrorMessage, ThemeTypings, Select, Textarea, NumberInput, NumberInputField, NumberInputStepper, NumberDecrementStepper, NumberIncrementStepper, HStack, VStack} from "@chakra-ui/react";
import { FieldValues, UseFormRegisterReturn, UseFormSetValue, ValidationRule, useForm } from "react-hook-form";
import { ApiResult, NoType } from "../../api/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { type } from "os";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";



export type ValidationProperty<TProperty> = {
    value: TProperty,
    errorMessage: string
}

type DefinitionType = "email" | "file" | "password" | "text"  | "date" | "number" | "textarea";
type Select = "select"

type AllTypes = DefinitionType | Select;


export type DisplayBehaviour = "single" | "multiple";
export type DefinitionItem<TModel, TKey extends keyof TModel> = {
    type: DefinitionType,
    rules: {
        required?: boolean,
        regexValidation?: ValidationProperty<RegExp>,
        validate?: (itemValue: string ,formValue: any) => string | undefined,
    },
    behaviour?: "single" | "multiple",
    label: string,
    placeHolder?: string;
    width?: string;
}

export type SelectDefinitionItem<TModel, TKey extends keyof TModel>  = 
({
    type: Select,
    values: TModel[TKey][],
    mapOptionText: (input: TModel[TKey]) => string,
    mapValue: (input: TModel[TKey]) => string | number,
} & Omit<DefinitionItem<TModel, TKey>, "type">);

export type FormDefinitionItem<TModel, TKey extends keyof TModel> = 
    SelectDefinitionItem<TModel, TKey> | DefinitionItem<TModel, TKey>;


export type FormDefinition<TModel> = {
    [key in keyof TModel]: FormDefinitionItem<TModel, key>
}


type ButtonCollor = "red" | "green";
export type FormActions= {
    width?: string,
    text: string,
    color: ButtonCollor,
    isSubmit?: boolean,
    action?: () => void
}[];

type BaseDisplayOptions = {
    marginLeft: string;
} & UseFormRegisterReturn<string>

type NumberDisplayOptions = Omit<BaseDisplayOptions, "onChange"> & {
    onChange: (text: string, newValue: number) => void;
}

type PlaceHolderExtendedDisplayOptions = {
    placeholder?: string
} & BaseDisplayOptions;

type TypeExtendedDisplayOptions = PlaceHolderExtendedDisplayOptions & {
    type: "email" | "password" | "text"  | "date" | "file"
};

type BoundType<TType extends AllTypes> =  (TType extends "select" | "textarea" ? PlaceHolderExtendedDisplayOptions: TypeExtendedDisplayOptions);

function isSelectDefinitionItem<TModel, TKey extends keyof TModel>(definitionItem: FormDefinitionItem<TModel, TKey>): definitionItem is SelectDefinitionItem<TModel, TKey>{
    const castedItem = definitionItem as unknown as SelectDefinitionItem<{key: string}, 'key'>;
    return castedItem.type == "select";
}
function mapColorsToScheme(color: ButtonCollor): ThemeTypings["colorSchemes"]{
    if(color == "green"){
        return "whatsapp"
    }
    return "red";
}
function mapOrUndefined<TInput, TResult>(input: TInput | undefined, mapFunc: (input: TInput) => TResult): TResult | undefined{
    return input !== undefined? mapFunc(input): undefined;
}

type CountModel<TModel, TKey extends keyof TModel> = 
    { countOfItems: number, valuesToUse: TModel[TKey] | null}

type FormCountModel<TModel> = {
    [key in keyof TModel]: CountModel<TModel, key>
}

export const createRegistrationName = (formFieldName: string, index: number): string => {
    return formFieldName + `_${index}`
};

export function GenericForm<TModel, TApiActionData=NoType, TResultModel=TModel, TInitialValue=TResultModel>(
    {
        width,
        initialValue,
        formActions,
        inputSpacing, 
        direction, 
        formLabel, 
        outlineStyles, 
        formDefinition, 
        afterSubmitAction, 
        validationErrorsPosition="before-actions-new-row", 
        submitAction,
        additionalBoottomJSX}: {
    width?: string | number,
    initialValue?: TInitialValue
    direction?: "row" | "column"
    formActions: FormActions,
    formLabel?: string,
    validationErrorsPosition?: "before-actions-new-row" | "after-actions-new-row"
    inputSpacing?: string,
    outlineStyles?: Record<string, string>,
    formDefinition: FormDefinition<TModel>, 
    submitAction: (model: TResultModel) => Promise<ApiResult<TApiActionData>>,
    afterSubmitAction: (input: TApiActionData) => void,
    additionalBoottomJSX?: () => JSX.Element}
){
    const BottomElement = additionalBoottomJSX ?? (() => <></>);
    direction = direction ?? "column";
    const {register, handleSubmit, formState: {errors, isDirty},unregister, setValue, reset} = useForm();
    const [countOfFormFieldsToDefinition, setCountModel] = useState<FormCountModel<TModel> | null>(null);
    const [validationErrors, setValidationErrors] = useState<string[] | null>(null);

    const mappedDefinitions = useMemo(() => {
        return Object.entries(formDefinition).map(([key, value]) => {
            const definitionItem = 
                value as FormDefinitionItem<TModel, keyof TModel>;
        
            const registrationFactory = (index: number) => {
                return createRegistration(createRegistrationName(key, index), definitionItem);
            }     
            const formDefinition =  {
                dispayOptions: {
                    label: definitionItem.label,
                    placeHolder: definitionItem.placeHolder,
                    type: definitionItem.type,
                    selectOptions: null as {optionText: string, optionValue: string | number}[] | null,
                    width: definitionItem.width,
                },
                isMultiple: definitionItem.behaviour == "multiple",
                fieldName: key,
                isRequired: !!definitionItem.rules.required,
                registrationFactory
            } as MappedDefinition<TModel>;
            if(isSelectDefinitionItem(definitionItem) ){
                //Here is safe cast because we alredy checked this case on top
                const selectItem = definitionItem as SelectDefinitionItem<TModel, keyof TModel>;
                const options = selectItem.values.map(val => {
                    return {
                        optionText: selectItem.mapOptionText(val),
                        optionValue: selectItem.mapValue(val),
                    }
                });
                formDefinition.dispayOptions.selectOptions = options;
            }
            return formDefinition;
    })}, [formDefinition]);

    const clearInitialValue = useCallback(() => {
        if(countOfFormFieldsToDefinition){
            const newCountModel = Object.fromEntries(Object.entries(countOfFormFieldsToDefinition).map(([key,val]) => {
                const castedValue = (val as  CountModel<TModel, keyof TModel>)
                const nextValue  = {
                    ...castedValue,
                    valuesToUse: null
                };
                return [key, nextValue];
            })) as FormCountModel<TModel>;
            setCountModel(newCountModel);
        }
    }, [countOfFormFieldsToDefinition])
    const updateModelCount = useCallback((fieldName: keyof TModel, countToAdd: number): void => {
            if(countOfFormFieldsToDefinition){
                const newCountModel = {
                    ...countOfFormFieldsToDefinition,
                    [fieldName]: {
                        ...countOfFormFieldsToDefinition[fieldName],
                        countOfItems: countOfFormFieldsToDefinition[fieldName].countOfItems + countToAdd
                    } as CountModel<TModel, keyof TModel>
                }
            setCountModel(newCountModel);
        }      
    }, [countOfFormFieldsToDefinition]);

    const addCountForFieldName =  useCallback((fieldName: keyof TModel) => {
        updateModelCount(fieldName, 1);
    }, [updateModelCount]);


    const removeCountForFieldName = useCallback((fieldName: keyof TModel) => {
       const currentCount = countOfFormFieldsToDefinition![fieldName].countOfItems;
       const registrationName = createRegistrationName(fieldName as string, currentCount - 1);
       unregister(registrationName);
       updateModelCount(fieldName, -1);
    }, [updateModelCount]);

    const createRegistration = (
         registrationName: string,
         definitionItem: FormDefinitionItem<TModel, keyof TModel>) => {
        return register(registrationName, {
            required: definitionItem.rules.required ?? false,
            pattern: mapOrUndefined(definitionItem.rules.regexValidation, (regexValidation) => (
                {
                    value: regexValidation.value,
                    message: regexValidation.errorMessage
                } as ValidationRule<RegExp>
            )) as any,
            validate: mapOrUndefined(definitionItem.rules.validate, (validatorFun) => validatorFun) as any
        })
    }

    

    useEffect(() => {
        if(!mappedDefinitions) return;
        let countModel: FormCountModel<TModel>;
        if(!!initialValue){
            countModel = Object.fromEntries(Object.entries(initialValue)
                .map(([key, val]) => {
                    const matchedFieldDefinition = 
                        mappedDefinitions.find(definition => definition.fieldName == key);
                    if(matchedFieldDefinition?.isMultiple){
                        //TODO leave that in assumption that value for multiple field must be an array
                        const castedValue = val as unknown[];
                        const value: CountModel<TModel, keyof TModel> = {
                            countOfItems: castedValue.length,
                            valuesToUse: castedValue as TModel[keyof TModel]
                        };
                        return [key, value];
                    }
                    const value: CountModel<TModel, keyof TModel> = {
                        countOfItems: 1,
                        valuesToUse: val as TModel[keyof TModel]
                    };
                    return [key, value];
                })) as FormCountModel<TModel>;
        }else{
            countModel = Object.fromEntries(mappedDefinitions.map((definition) => {
                const key = definition.fieldName;
                const value: CountModel<TModel, keyof TModel> = {
                    countOfItems: 1,
                    valuesToUse: null
                };
                return [key, value];
            })) as FormCountModel<TModel>;
        }
        setCountModel(countModel);
    }, [mappedDefinitions, initialValue])


    useEffect(() => {
        if(!initialValue) return;
        if(!countOfFormFieldsToDefinition) return;
        console.log(countOfFormFieldsToDefinition);
        const valueWasChanged = mappedDefinitions.map((definition) => {
            if(definition.dispayOptions.type == "file") return false;
            const countModelValue = countOfFormFieldsToDefinition[definition.fieldName];
            if(countModelValue.valuesToUse){
                if(definition.isMultiple){
                    for(let i = 0; i < countModelValue.countOfItems; i++){
                        const registrationName = createRegistrationName(definition.fieldName as string, i);
                        setValue(registrationName, (countModelValue.valuesToUse as unknown[])[i])
                    }   
                    return true;
                }else{
                    const registrationName = createRegistrationName(definition.fieldName as string, 0);
                    setValue(registrationName, countModelValue.valuesToUse)
                    return true;
                }
            }
            return false;
        }).some(res => res);

        if(valueWasChanged){
            clearInitialValue();
        }
        
    }, [countOfFormFieldsToDefinition, mappedDefinitions])


    const submitHandler = (data: object) => {
            console.log('submit invoked');
            if(validationErrors){
                setValidationErrors(null);
            }

            const keyToArrayValue = Object.entries(data)
                .map(([key, val]) => {
                    const parsedKey = key.split("_")[0];
                    return [parsedKey, val];
                } ).reduce((aggregated, [key, val]) => {
                    return {
                        ...aggregated,
                        [key]: [...(aggregated[key] ?? []), val]
                    }
                }, {} as any);

            const resultModel = Object.fromEntries(Object.entries(keyToArrayValue).map((entry) => {
                const [key, val] = entry as [string, unknown[]];
                const matchedDefinition = mappedDefinitions.find(definition => definition.fieldName == key)!;
                if(val.length > 1 || matchedDefinition.isMultiple){
                    return [key, val];
                }
                return [key, val[0]];
            } ));

                    
            submitAction(resultModel as TResultModel).then((result) => {
                if(result.success){
                    reset();
                    afterSubmitAction(result.data as TApiActionData);
                }else{
                    setValidationErrors(result.data.errors);
                }
            });
        };

    
    const createInputFromDefinition = (
        definition: MappedDefinition<TModel>,
        itemNumber: number,
        addLabel: boolean = true ) => {
        const createOpts = createInputDisplayOptions(
             itemNumber,
             definition,
             setValue);
        let inputJsx: JSX.Element;
        if(definition.dispayOptions.type == "select" && definition.dispayOptions.selectOptions){ 
            const iterationOpts = createOpts(definition.dispayOptions.type);
            inputJsx = <Select width={definition.dispayOptions.width ?? "fit-content"} {...iterationOpts}>
                {definition.dispayOptions.selectOptions.map((opt, index) => 
                    (<option key={index} value={opt.optionValue}>{opt.optionText}</option>))}
            </Select>
        }else if(definition.dispayOptions.type == 'textarea'){
            const iterationOpts = createOpts(definition.dispayOptions.type);
            inputJsx = <Textarea width={definition.dispayOptions.width ?? "fit-content"} {...iterationOpts}/>;   
        }
        else if(definition.dispayOptions.type === "file"){
            const displayOpts = createOpts(definition.dispayOptions.type);
            const resultOpts = Object.fromEntries(Object.entries(displayOpts).filter(([key, val]) => key !=="marginLeft"));
            inputJsx = <Box width={definition.dispayOptions.width ?? "fit-content"} marginLeft={displayOpts.marginLeft}><input 
                        width="fit-content" 
                        {...resultOpts}/></Box>
        }
        else{
            const iterationOpts = createOpts(definition.dispayOptions.type);
            inputJsx = <Input {...iterationOpts} width={definition.dispayOptions.width ?? "fit-content"} type={definition.dispayOptions.type}/>
        }
        const formContent = <Flex alignItems="center" flexWrap="wrap">
                                <FormLabel visibility={addLabel? "visible": "hidden"} whiteSpace="nowrap" margin="0" marginBottom="0.5em" marginRight="1em">{definition.dispayOptions.label}</FormLabel>
                                {inputJsx}
                            </Flex>;
        return formContent;
    }


    const createFormControl = (
        content: JSX.Element, 
        definition: MappedDefinition<TModel>, 
        index: number): JSX.Element => {
        const registrationName = createRegistrationName(definition.fieldName as string, index);
        if(definition.dispayOptions.type == "file"){
            return <Box>{content}</Box>;
        }
        return <FormControl 
                 isInvalid={!!errors[registrationName]}>
            {content}
            {errors[registrationName] && (<FormErrorMessage maxWidth="10vw">
                {errors[registrationName]?.message as string}
            </FormErrorMessage>)}
        </FormControl>
    }

    if(!countOfFormFieldsToDefinition){
        return <h1>Loading form...</h1>;
    }


    const formItems = mappedDefinitions.map((definition, index) => {
        let formContent: JSX.Element;
        if(definition.isMultiple){
            const countModel = countOfFormFieldsToDefinition[definition.fieldName];
            const requestedInputs = [];
            for(let i = 0; i < countModel.countOfItems; i++){
                const showInputLabel: boolean = i == 0;
                requestedInputs.push(createInputFromDefinition(definition, i, showInputLabel));
            }
            const wrappedFormControls = requestedInputs.map((input, index) => 
                    createFormControl(input, definition, index));
            const inputContent = <Flex key={index} direction="column" alignItems="flex-start">
                                    <VStack spacing="1.2em">
                                        {wrappedFormControls.map((input, index) => <Box key={index}>{input}</Box>)}
                                    </VStack>
                                    <Flex alignItems="center" marginTop="0.5em" justifyContent="center" width="100%">
                                            <Button onClick={() => addCountForFieldName(definition.fieldName)}>
                                                <AddIcon boxSize={3}/>
                                            </Button>
                                            <Button onClick={() => removeCountForFieldName(definition.fieldName)} marginLeft="0.5em">
                                                <MinusIcon boxSize={3} />
                                            </Button>
                                    
                                    </Flex>
                          </Flex>;
            return inputContent;
        }
        const formControl = createFormControl(createInputFromDefinition(definition, 0), definition, 0);
        return <Box key={index}>{formControl}</Box>;
        });


    
    const ValidationErrors = () => {
        if(validationErrors){
            return <>{validationErrors.map((err, index) => <Text key={index} color="red" fontSize="1.2em">{err}</Text>)}</> 
        }
        return <></>;
    }


    const buttonActions = formActions.map((action, index) => {
        let buttonProperties: any = {
            key: index, 
            width: action.width ?? "30%",
            colorScheme: mapColorsToScheme(action.color),
            ml: index > 0? "1em" : "unset"
        };
        if(action.isSubmit){
            buttonProperties = {...buttonProperties,
                                type: "submit"};
        }else if(action.action){
            buttonProperties = {...buttonProperties,
                                onClick: action.action};
                 }
        return <Button {...buttonProperties}>
                {action.text}
             </Button>;

    });



    return <form style={width? {"width": width}: {}} onSubmit={handleSubmit(submitHandler)}>
            <Stack {...(outlineStyles ?? {})}>
                <Stack alignItems="center" direction={direction} wrap="wrap" spacing={inputSpacing ?? "1.8em"} >
                    {!!formLabel && <Text margin="0 auto" fontSize="1.4em" fontWeight="500">{formLabel}</Text>}
                    {formItems}
                    {validationErrorsPosition == "before-actions-new-row" && <ValidationErrors/>}
                    <BottomElement/>
                    <Flex alignItems="center" width="fit-content">
                        {buttonActions}
                    </Flex>
                </Stack>
            </Stack>
        </form>

}



type SelectOption = { optionText: string; optionValue: string | number; };
type MappedDefinition<TModel> =  {
     dispayOptions: { 
        width?: string;
        label: string; 
        placeHolder: string | undefined; 
        type: "select" | DefinitionType; 
        selectOptions: SelectOption[] | null; 
    };
    isRequired: boolean,
    isMultiple: boolean,
    fieldName: keyof TModel,
    registrationFactory: (index:number) => UseFormRegisterReturn<string>}; 

function createInputDisplayOptions<TModel>(
    itemNumber: number,
    definition: MappedDefinition<TModel>,
    setValue: UseFormSetValue<FieldValues>) {
        const genericOptions = { ...definition.registrationFactory(itemNumber) };
        const getOpts =  <TRequestType extends AllTypes>(type: TRequestType): BoundType<TRequestType> => {
            const withPlaceHolderTypes = {
                ...genericOptions,
                placeholder: definition.dispayOptions.placeHolder
            };
            if (type == 'select' || type == "textarea") {
                return withPlaceHolderTypes as BoundType<TRequestType>;
            }
            return {
                ...withPlaceHolderTypes,
                type: definition.dispayOptions.type,
            } as BoundType<TRequestType>;
        }
    return getOpts;
};

