import { create } from "zustand";
import { IVoucher } from "../components/Main/components/Admin/components/Voucher";

export type EditVoucherResult  = "rejected" | "updated";


export type EditVoucherModal = {
    isDisplayed: boolean;
    result: EditVoucherResult | null,
    requestedVoucher: IVoucher | null;
    show: (requestedVoucher: IVoucher) => void;
    hide: (result: EditVoucherResult) => void;
    clearResult: () => void;
}


export const useEditVoucherModalStore = create<EditVoucherModal>((set, get) => {
    return {
        clearResult: () => {
            set(() => ({result: null, requestedVoucher: null}));
        },
        hide: (editResult) => {
            if(!get().isDisplayed) return;
            set(() => ({isDisplayed: false, result: editResult}));
        },
        isDisplayed: false,
        requestedVoucher: null,
        result: null,
        show: (requestedVoucher: IVoucher) => {
            if(get().isDisplayed) return;
            set(() => ({isDisplayed: true, requestedVoucher}));
        }
    }
})