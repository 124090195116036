import { axiosInstance } from "../api/axios";
import { ApiResult, ApiSuccessResult, NoType, handleError } from "../api/types";
import { ResultProductModel } from "../components/Main/components/Admin/components/Products";
import { ISailItem } from "../components/Main/components/GoodsPage/components/SailItems/SailItems";
import { stringifyPrice } from "../shared/PriceManagement";

export interface IApiProductModel{
    photoUrl: string,
    id: number,
    name: string;
    availableCount: number,
    price: number;
    currency: string;
    categoryId: number;
    productDescription: string;
    productDownText: string;
}

type ProductResponseModel = IApiProductModel;

type ProductWithUserMessageResponseModel= ProductResponseModel &  {
    userMessages: string[];
}

type RangeSailItemsRequest = {
    ids: number[]
}

export function loadSailItemsWithCategoryId(categodyId: number): Promise<ApiResult<ProductResponseModel[]>>{
    return handleError(axiosInstance.get(`/Product/category/${categodyId}`).then(res => {
        return {
            data: res.data as ProductResponseModel[],
            success: true
        } as ApiSuccessResult<ProductResponseModel[]>
    }));
}

export function loadSailItemsWtihIds(sailItemsIds: number[]): Promise<ApiResult<ProductResponseModel[]>>{
    return axiosInstance.post("/Product/range", {
        ids: sailItemsIds
    } as RangeSailItemsRequest)
    .then((res) => {
        return {
            success: true,
            data: res.data as ProductResponseModel[]
        };
    });
}

export function loadDetailedSailItemWithId(sailItemId: number){

}
export function loadSailItemWithId(sailItemId: number): Promise<ApiResult<ProductResponseModel>> {
    return handleError(axiosInstance.get(`/Product/${sailItemId}`).then(prod => {
        return {
            success: true,
            data: prod.data as ProductResponseModel
        }
    }));
}

export function deleteSailItem(sailItemId: number): Promise<ApiSuccessResult>{
    return axiosInstance.delete(`/Product/${sailItemId}`).then(res => {
        return {
            success: true,
            data: { noType: true } as NoType
        } as unknown as ApiSuccessResult;
    })
}

function createFormData(model: ResultProductModel): FormData{
    const formData = new FormData();
    if(model.productImage.length !== 0){
        const requestedFile = model.productImage[0];
        formData.append("ProductImage", requestedFile);
    }
    formData.append("ProductDescription", model.productDescription);
    formData.append("CategoryId", model.category.toString());
    formData.append("Name", model.name);
    formData.append("Price", model.price);
    formData.append("Currency", model.unitOfPrice);
    formData.append("AvailableCount", (model.userUnderstendableProduct?.length ?? 0).toString());
    formData.append("ProductDownText", model.productDownText);
    //TODO check that poin
    formData.append("MailFileContent", JSON.stringify(model.userUnderstendableProduct ?? []));
    return formData;
}

export function createSailItem(model: ResultProductModel): Promise<ApiResult>{
        const formData = createFormData(model);
        return handleError(axiosInstance.post("/Product", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
          }
        }).then(res => {
            return {
                success: true,
                data: { noType: true } as NoType
            } as unknown as ApiSuccessResult;
        }));
}

export function updateSailItem(model: ResultProductModel & {id: number}): Promise<ApiResult>{
    const formData = createFormData(model);
    formData.append("Id", model.id.toString());
    return handleError(axiosInstance.patch("/Product", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
      }
    }).then(res => {
        return {
            success: true,
            data: { noType: true } as NoType
        } as unknown as ApiSuccessResult;
    }));
}

export function mapSailItem(item: IApiProductModel): ISailItem{
    return {
        name: item.name,
        id: item.id,
        availableCount: item.availableCount,
        categoryId: item.categoryId,
        photoUrl: item.photoUrl,
        price: item.price,
        priceString: stringifyPrice(item.price) + " " + item.currency,
        productDescription: item.productDescription,
        productDownText: item.productDownText
    } as ISailItem;
}




export function loadSailItemsWithUserMessage(productId: number): Promise<ApiResult<ProductWithUserMessageResponseModel>>{
    return handleError(axiosInstance.get(`/Product/${productId}/WithUserMessage`).then(
        (success) => {
            return {
                success: true,
                data: success.data as ProductWithUserMessageResponseModel
            };
        }
    ));
}
