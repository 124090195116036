import { useEffect, useState } from "react";
import { createCategory, deleteCategory, loadCategories, updateCategoryName } from "../../../../../categoryManagement/CategoryManagement";
import { Editable, Text, EditableInput, EditablePreview, Flex, Input, Stack, Box } from "@chakra-ui/react";
import { FormDefinition, GenericForm } from "../../../../Forms/GenericForm";
import { ICategory } from "../../GoodsPage/components/CategoryList/CategoriesList";
import { CheckIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";


type CategoryModel ={
    name: string;
}
const categoryCreateDefinition: FormDefinition<CategoryModel> = {
    name: {
        type: "text",
        rules: {
            required: true,
            validate: (newName) => {
                if(newName == ""){
                    return "Category name could not be empty";
                }
                return undefined;
            }
        },
        label: "Category name:",
        placeHolder: "name"
    }
} 
export const CreateCategory = ({afterSumbitAction}: {afterSumbitAction: () => void}) => GenericForm({
    validationErrorsPosition: "after-actions-new-row",
    formActions: [
    {
        color: "green",
        text: "Create",
        isSubmit: true,
        width: "100px"
    }
    ],
    afterSubmitAction: afterSumbitAction,
    direction: "row",
    formDefinition: categoryCreateDefinition,
    submitAction: (modeel) => {
        return createCategory({
            name: modeel.name
        });
    },
    inputSpacing: "0.8em"
    },
);

const EditableCategory = (
{category, changeCommited}:{
    category: ICategory,
    changeCommited: () => void}) => 
{

    const formFieldName = "categoryName";
    const {register, handleSubmit, watch} = useForm();
    const [viewState, setViewState] = useState<"Edited" | "Editing" | "Untouched">("Untouched");
    const [errors, setErrors] = useState<string[]>([]);

    const persistUpdateCategoryName = (newName: string) => {
        updateCategoryName({
            id: category.id,
            name: newName
        }).then((result) => {
            if(result.success){
                setViewState("Edited");
                changeCommited();
            }else{
                setErrors(result.data.errors);
            }
        });
    }
    useEffect(() => {
        const watchFileNameChanged = watch((data) => {
            if(data[formFieldName]){
                if(data[formFieldName] !== category.name ){
                    setViewState("Editing");
                }else if(data[formFieldName] === category.name){
                    setViewState("Untouched");
                }
            }
        });
        return () =>
             watchFileNameChanged.unsubscribe();
    }, [watch]);

    const unpersistCategory = () => {
        console.log(category.id);
        deleteCategory(category.id).finally(() => {
            changeCommited()
        }); 
    }

    const handleChange: SubmitHandler<FieldValues> = (model) => {    ;
        const changedCategoryName = model[formFieldName];
        persistUpdateCategoryName(changedCategoryName);
    }
    return <Stack padding="0.3em" border="1px solid gray" borderRadius="0.4em" width="fit-content"> 
            <Stack alignItems="center" direction="row" spacing="0.3em">
                <Text fontWeight="500">Category: </Text>
                <Editable defaultValue={category.name}>
                    <EditablePreview />
                    <EditableInput {...register(formFieldName)} />
                </Editable>
                {viewState == "Editing" && <CheckIcon color="green" onClick={handleSubmit(handleChange)}/>}
                {category.id !== -1 &&  <SmallCloseIcon marginTop="0.2em" color="red" onClick={unpersistCategory}/>}
            </Stack>
            {errors.length > 0 && 
                <Stack>
                    {errors.map((err, index) => 
                        <Text color="red" fontSize="1.2em" key={index}>{err}</Text>)}
                </Stack>}
        </Stack>
}
export function EditableCategories()
{
    const [catgories, setCategories] = useState<ICategory[]>([]);
    const updateCategories = () => {
            console.log('change commited');
            loadCategories().then((loadedCategories) => {
                setCategories(loadedCategories.data)
        });
    }

    useEffect(() => {
        updateCategories();
    }, []);

    const mappedCategories = catgories.map((catergory) => <EditableCategory key={catergory.id} category={catergory} 
                                            changeCommited={updateCategories}/>)
    
    return <Stack spacing="1.2em">
                <Box width="40%"><CreateCategory afterSumbitAction={updateCategories}/></Box>  
                {mappedCategories}
        </Stack>
}