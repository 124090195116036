import { Flex, Heading, Stack, Text, Button, Box, Link, HStack } from "@chakra-ui/react";
import { Balance } from "./components/Balance";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IInformationModalData, useInformationModal } from "../../../../stores/informationtModal";
import { stringifyPrice } from "../../../../shared/PriceManagement";
import { loadPayments } from "../../../../paymentManagement/PaymentManagement.mapper";
import moment from 'moment';

import { SailItemImage } from "../GoodsPage/components/SailItems/SailIttemImage";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../../../customtables/src/table";
import { useResizeOnChange } from "../../../../hooks/useResizeOnChange";
import { useAuthorization } from "../../../../identiyManagement/hooks";
import { finalizePaymentApiCall } from "../../../../paymentManagement/PaymentManagement";
import { PaymentLink } from "../../../../shared/PaymentLink";
export type PaymentStatus = "Confirmed" | "Rejected" | "WaitingForPay"
export type PaymentTypes = "BalanceTopUp" | "PaymentFromWallet" | "PaymentFromBalance";

export interface IBasePaymentModel<TStatus extends PaymentStatus, TType extends PaymentTypes>{
    dateOfCreation: Date;
    dateOfConfirmation: Date | null;
    paymentAmount: number;
    status: TStatus;
    type: TType;
    email: string;
    paymentId: number;
}

export type StrippedProduct = {
    productName: string;
    productPrice: number;
    productPhotoUrl: string;
}
export type ConfirmedPaymentModel<TType extends PaymentTypes, TExtension={}> = IBasePaymentModel<"Confirmed", TType> & TExtension;
export type RejectedPaymentModel<TType extends PaymentTypes, TExtension={}> = IBasePaymentModel<"Rejected", TType> & TExtension;
export type PendingPaymentModel<TType extends PaymentTypes, TExtension extends {url: string} = {url: string}> = IBasePaymentModel<"WaitingForPay", TType> & TExtension;

export type PurchasedProductsModel = {
    products: {
        product: StrippedProduct,
        attachedItems: string[]
    }[]
}

export type BalanceTopUpModel = ConfirmedPaymentModel<"BalanceTopUp"> | RejectedPaymentModel<"BalanceTopUp"> | PendingPaymentModel<"BalanceTopUp">
export type PaymentFromWalletModel = ConfirmedPaymentModel<"PaymentFromWallet", PurchasedProductsModel>
                                | RejectedPaymentModel<"PaymentFromWallet">  | PendingPaymentModel<"PaymentFromWallet">
export type PaymentFromBalanceModel = ConfirmedPaymentModel<"PaymentFromBalance", PurchasedProductsModel>


export type PaymentModel = BalanceTopUpModel | PaymentFromWalletModel | PaymentFromBalanceModel;


export const PayedProductsComponents = ({model}: {model: PurchasedProductsModel}): JSX.Element => {
    return <Stack fontSize="1.4em">
        {model.products.map((productGroup, index) => (<Stack key={index} borderTop="0.05em solid" borderColor="gray.500" padding="1em 0" spacing="1em">
        <Flex alignItems="center" justifyContent="flex-start">
            <SailItemImage sizingStrategy="fit-content" handleHower={false} sailItem={{
                name: productGroup.product.productName,
                photoUrl: productGroup.product.productPhotoUrl
            }} />
            <Box marginLeft="1em">{stringifyPrice(productGroup.product.productPrice)}</Box>
        </Flex>
        <Text>Purchased items:</Text>
        {productGroup.attachedItems.map((item, index) => <Box key={index}>{item}</Box>)}
        </Stack>
        ))}
    </Stack>
};
const detailModalContentFactory = (model: PaymentModel ): IInformationModalData =>{

    if((model.type == "PaymentFromBalance" || model.type == "PaymentFromWallet") && model.status == "Confirmed"){
        return {
            headerName: mapType(model.type) + " purchased items",
            component: <PayedProductsComponents model={model}/>
        }
    }else if(model.status == "Rejected"){
        return {
            headerName: mapType(model.type) + " was rejected",
            component: <Text fontSize="1.2em">
                Payment was rejected because you haven't payed invoice in hour
                </Text>
        }
    }else if(model.type == "BalanceTopUp" && model.status == "Confirmed"){
        return {
            headerName: mapType(model.type) + " confirmed",
            component: <Text fontSize="1.2em">
                Balance payment was confirmed, you could see that your balance counter have updated
                </Text>
        }
    }else if(model.status == "WaitingForPay"){
        return {
            headerName: "Waiting for your payment",
            component: <Flex fontSize="1.2em" alignItems="center" flexDirection="column">
                            <Box whiteSpace="nowrap" marginRight="0.2em" fontWeight="600">Payment url:</Box>
                            <Link marginLeft="1em" href={model.url} isExternal  wordBreak="break-all">{model.url}</Link>
                       </Flex>
        }
    }

    throw new Error("Unknown model type and status combination")
}

const mapType = (type: PaymentTypes): string => {
    if(type == "BalanceTopUp"){
        return "Balance top up";
    }else if(type == "PaymentFromBalance") {
        return "Payment from balance";
    }else if(type == "PaymentFromWallet"){
        return "Payment from wallet";
    }
    throw new Error("Unknown payment type");
}
const mapStatusColumn = (status: PaymentStatus): JSX.Element => {
    const mapStatus = (status: PaymentStatus): string => {
        if(status == "Confirmed" || status == "Rejected"){
            return status;
        }else if(status == "WaitingForPay"){
            return "Waiting for pay";
        }
        throw new Error("Unknow status type");
    }
    const mapStyles = (status: PaymentStatus): {color: string} => {
        if(status === "Rejected"){
            return { color: "#E53E3E" };
        }
        if(status == "Confirmed"){
            return { color: "#38A169" }
        }
        if(status == "WaitingForPay"){
            return { color: "#63B3ED" };
        }
        throw new Error("Unknown status type")
    }
    const styles = mapStyles(status);
    const statusString = mapStatus(status);
    return <Td textAlign="center"><Box {...styles}>{statusString}</Box></Td>
}
function stringifyExparationDate(date: Date | null): string{
    return date === null? "Not confirmed yet": moment(date).format('MMMM Do, YYYY h:mm a');
}

type DisplayMode = "Shrinked" | "Full";



export function Purchases(){
        const {userRole} = useAuthorization();
        const isUserAdmin = useMemo(() => {
                return userRole == "Admin";
        }, [userRole])
        const content = useMemo(() => {
                if(isUserAdmin){
                        return <AdminPurchases/>
                }
                return <RegurlarPurchases/>
        },[isUserAdmin]);

       return content;
}





function RegurlarPurchases()
{
    const [paymentModels, setPaymentModels] = useState<PaymentModel[] | null>();
    const showInformationalModal = useInformationModal(modal => modal.show);
    const displayMode = useResizeOnChange<DisplayMode>((width) => width < 570? "Shrinked": "Full");
    const loadPurchases = useCallback(() => {
        loadPayments().then(loadRes => {
            if(loadRes.success){
                setPaymentModels(loadRes.data);
            }
        })
    }, []);

    useEffect(() => {
        loadPurchases();
    }, []);


    const detailsClickHandler = useCallback((productModel: PaymentModel): void => {
        showInformationalModal(detailModalContentFactory(productModel));
    }, []);

    const createPurchasesRow = useCallback((paymentModel: PaymentModel, index: number) => {
        const boundClickHandler = detailsClickHandler.bind(detailsClickHandler, paymentModel);
        return <Tr key={index}>
                <Td textAlign="center">{stringifyExparationDate(paymentModel.dateOfCreation)}</Td>
                <Td textAlign="center">{stringifyExparationDate(paymentModel.dateOfConfirmation) ?? "Not confirmed yet"}</Td>
                <Td textAlign="center">{stringifyPrice(paymentModel.paymentAmount)}</Td>
                <Td textAlign="center">{mapType(paymentModel.type)}</Td>
                {mapStatusColumn(paymentModel.status)}
                <Td textAlign="center" >
                    <HStack spacing="1em" justifyContent="center" flexWrap="wrap">
                        <Button onClick={boundClickHandler} colorScheme="green">{displayMode == "Full"? "Show details" : "Details"}</Button>
                    </HStack>
                </Td>
              </Tr>
    }, []);
    const fullModeTexts = useMemo(() => {
        const defaultItems = [
            "Date of creation",
            "Date of confirmation",
            "Total  amount",
            "Payment type",
            "Payment status",
            "Payment details"
        ];
        return [...defaultItems];
    }, []);

    let purchasesContent!: JSX.Element;
    if(paymentModels == null){
        purchasesContent = <Text fontSize="1.4em">Loading payments</Text>;
    }else{
        const resultTexts = fullModeTexts;
        purchasesContent =
        <Table width="100%" variant="simple">
            <Thead>
                <Tr>
                   {resultTexts.map((text, index) => <Th textAlign="center" key={index}>{text}</Th>)}
                </Tr>
            </Thead>
            <Tbody>
                {paymentModels.map((model, index) => createPurchasesRow(model, index))}
            </Tbody>
        </Table>
    }
    return <Stack alignItems="center" spacing="3.5em">
        <Flex marginTop="2em" justifyContent="flex-start" width="100%"><Balance/></Flex>
        <Heading>Purchases</Heading>
        {purchasesContent}
    </Stack>

}
function AdminPurchases(){
    const [paymentModels, setPaymentModels] = useState<PaymentModel[] | null>();
    const showInformationalModal = useInformationModal(modal => modal.show);
    const displayMode = useResizeOnChange<DisplayMode>((width) => width < 570? "Shrinked": "Full");
    const loadPurchases = useCallback(() => {
        loadPayments().then(loadRes => {
            if(loadRes.success){
                setPaymentModels(loadRes.data);
            }
        })
    }, []);

    useEffect(() => {
        loadPurchases();
    }, []);

    const finalizePayment = useCallback((paymentId: number) => {
        finalizePaymentApiCall(paymentId).then((res) => {
            if(res.success){
                loadPurchases();
            }
        })

    },[]);

    const detailsClickHandler = useCallback((productModel: PaymentModel): void => {
        showInformationalModal(detailModalContentFactory(productModel));
    }, []);

    const createPurchasesRow = useCallback((paymentModel: PaymentModel, index: number) => {
        const boundClickHandler = detailsClickHandler.bind(detailsClickHandler, paymentModel);
        return <Tr key={index}>
                <Td textAlign="center">{paymentModel.email}</Td>
                <Td textAlign="center">{stringifyExparationDate(paymentModel.dateOfCreation)}</Td>
                <Td textAlign="center">{stringifyExparationDate(paymentModel.dateOfConfirmation) ?? "Not confirmed yet"}</Td>
                <Td textAlign="center">{stringifyPrice(paymentModel.paymentAmount)}</Td>
                <Td textAlign="center">{mapType(paymentModel.type)}</Td>
                {mapStatusColumn(paymentModel.status)}
                <Td textAlign="center" >
                    <HStack spacing="1em" justifyContent="center" flexWrap="wrap">
                        <Button onClick={boundClickHandler} colorScheme="green">{displayMode == "Full"? "Show details" : "Details"}</Button>
                        {paymentModel.status == "WaitingForPay" &&
                        <Button onClick={() => finalizePayment(paymentModel.paymentId)} colorScheme="red">Finalize</Button>}
                    </HStack>
                </Td>
              </Tr>
    }, []);
    const fullModeTexts = useMemo(() => {
        const defaultItems = [
            "User info",
            "Date of creation",
            "Date of confirmation",
            "Total amount",
            "Payment type",
            "Payment status",
            "Payment details"
        ];
        return [...defaultItems];
    }, []);

    let purchasesContent!: JSX.Element;
    if(paymentModels == null){
        purchasesContent = <Text fontSize="1.4em">Loading payments</Text>;
    }else{
        const resultTexts = fullModeTexts;
        purchasesContent =
        <Table width="100%" variant="simple">
            <Thead>
                <Tr>
                   {resultTexts.map((text, index) => <Th textAlign="center" key={index}>{text}</Th>)}
                </Tr>
            </Thead>
            <Tbody>
                {paymentModels.map((model, index) => createPurchasesRow(model, index))}
            </Tbody>
        </Table>
    }
    return <Stack alignItems="center" spacing="3.5em">
        <Flex marginTop="2em" justifyContent="flex-start" width="100%"><Balance/></Flex>
        <Heading>Purchases</Heading>
        {purchasesContent}
    </Stack>
}
