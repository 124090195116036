import { axiosInstance } from "../api/axios";
import { ApiFaultResult, ApiResult, ApiSuccessResult, NoType } from "../api/types";

export interface IProductIdToAmount{
    amount: number;
    productId: number;
}

export interface IPaymentRequestModel{
    productsToPurchase: IProductIdToAmount[];
    merchant?: string;
    voucher?: string;
}

export interface IFinalizePaymentRequestModel{
    paymentId: number;
}

export interface IPaymentResponseErrorModel{
    reason: string,
    failedIds: number[]
}

export interface IWalletPaymentSuccessModel {
    paymentUrl: string;
}

export interface IAnonymusPaymentSuccessModel extends IWalletPaymentSuccessModel{
    email: string | null;
    password: string | null;
}

export function createPayment(model: IPaymentRequestModel): Promise<ApiResult<IWalletPaymentSuccessModel, IPaymentResponseErrorModel>>{
    return handlePaymentError(axiosInstance.post("/Payment/fromWallet", model).then((resp) => {
        const paymentSuccessData = resp.data as IWalletPaymentSuccessModel;
        return {
            success: true,
            data: paymentSuccessData
        } as ApiSuccessResult<IWalletPaymentSuccessModel>
    }));

} 
export interface IAnonymusPaymentModel{
    requestedEmail: string;
    voucher: string | null;
    productId: number;
    productAmount: number;
    merchant: string;
}

export function createAnonymusPayment(model: IAnonymusPaymentModel): Promise<ApiResult<IAnonymusPaymentSuccessModel, IPaymentResponseErrorModel>>{
    return handlePaymentError(axiosInstance.post("/Payment/fromWalletAnonymous", model).then(succ => {
        return {
            success: true,
            data: succ.data as IAnonymusPaymentSuccessModel
        };
    }));
}

export function createBalancePayment(model: IPaymentRequestModel): Promise<ApiResult<NoType, IPaymentResponseErrorModel>>{
    return handlePaymentError(axiosInstance.post("/Payment/fromBalance", model).then((res) => {
        return {
            success: true
        } as ApiSuccessResult;
    }));
}
export function finalizePaymentApiCall(paymentId: number): Promise<ApiSuccessResult>{
    const finalizeRequestModel: IFinalizePaymentRequestModel = {
        paymentId
    };
    return axiosInstance.post("/Payment/finalize", finalizeRequestModel).then(res => {
        return {
            success: true
        } as ApiSuccessResult;
    })
}

function handlePaymentError<TResult extends ApiSuccessResult<unknown>>(promise: Promise<TResult>)
    : Promise<TResult | ApiFaultResult<IPaymentResponseErrorModel>>
{
    return promise.catch(err => {
        if(err.response){
            return {
                success: false,
                data: err.response.data as IPaymentResponseErrorModel
            } as ApiFaultResult<IPaymentResponseErrorModel>
        }   
        throw new Error("Unknown response error");
    });
}


