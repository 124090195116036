import { Box, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useIdentityStore } from "../../../../stores/userIdentity";
import { UserRole } from "../../../../identiyManagement/IdentityManagement";
import { useAuthorization } from "../../../../identiyManagement/hooks";
import { BiHomeAlt2 } from "react-icons/bi"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { MdOutlinePayment, MdOutlineAdminPanelSettings } from "react-icons/md"
import { BiLogIn, BiLogOut } from "react-icons/bi"
import { BsPersonAdd } from "react-icons/bs"
import { LinksBurderMenu } from "./comonents/LinksBurgerMenu";
import { LinksFlatList } from "./comonents/LinksFlatList";
import { useResizeOnChange } from "../../../../hooks/useResizeOnChange";
import React, { useMemo } from "react";
import { contentOutlineStyles } from "../../../../shared/styles/content-outline";



interface NavLink {
    text: string;
    icon: JSX.Element,
    navigationPath: string;
    shouldBeUserAuthenticated?: boolean,
    targetRole?: UserRole;
    clickHandler?: () => void;
}


const defaultNavLinks: NavLink[] = [
    {
        text: "Main page",
        navigationPath: "/",
        icon: <BiHomeAlt2 size="1.5em"/>
    },
    {
        text: "Cart",
        navigationPath: "/cart",
        icon: <AiOutlineShoppingCart size="1.5em" />
    },
    {
        text: "Payments",
        navigationPath: "/payments",
        icon: <MdOutlinePayment size="1.5em"/>,
        shouldBeUserAuthenticated: true
    },
    {
        text: "Login",
        navigationPath: "/login",
        icon: <BiLogIn size="1.5em"/>,
        shouldBeUserAuthenticated: false
    },
    {
        text: "Register",
        icon: <BsPersonAdd size="1.5em"/>,
        navigationPath: "/register",
        shouldBeUserAuthenticated: false
    },
    {
        text: "Admin",
        navigationPath: "/admin",
        icon: <MdOutlineAdminPanelSettings size="1.5em"/>,
        shouldBeUserAuthenticated: true,
        targetRole: "Admin",
    },
]
const createLinks = (email: string | null, logoutHandler: () => void) => {
    const resultLinks = (email !== null)? [...defaultNavLinks, {
        text: email + ", Logout",
        navigationPath: "/",
        icon: <BiLogOut size="1.5em"/>,
        shouldBeUserAuthenticated: true,
        clickHandler: logoutHandler,
    }]: defaultNavLinks;
    
    return resultLinks;
}

export interface HeaderProps{
    name: string;
}

export type RenderedLink = {
    content: JSX.Element;
    clickHandler: () => void;
}
type DisplayMode = "burger" | "flat-list";

export function Header({name}: HeaderProps){
    const logout = useIdentityStore((store) => store.logout);
    const navigation = useNavigate();
    const displayMode = useResizeOnChange<DisplayMode>((width) => {
        if(width < 970){
            return "burger";
        }
        return "flat-list";
    });
    const {isAuthenticated, email, userRole} = useAuthorization();

    const notIdentityFilteredLinks = useMemo(() => {
        return createLinks(email, logout);
    }, [email]);

    const resultLinks = notIdentityFilteredLinks.filter(link => link.shouldBeUserAuthenticated == undefined ||
                                                            (link.shouldBeUserAuthenticated === isAuthenticated 
                                                                && (!link.targetRole || link.targetRole == userRole)))

   

                                                            
    const linksList =  resultLinks
        .map<RenderedLink>((link, index) => {
            const linkContent = <Flex alignItems="center">
                <Box marginRight="0.5em" >{link.icon}</Box> 
                <Box 
                    wordBreak="break-word" 
                    whiteSpace="pre-line">{link.text}</Box>                
                </Flex>

            return  {
                content: <>
                        <Box>{linkContent}</Box>
                    </>,
                clickHandler: () =>{
                    navigation(link.navigationPath);
                    if(link.clickHandler){
                        link.clickHandler();
                    }
                }}
        });

    return <nav>
            <Flex align="center" {...contentOutlineStyles} justifyContent="space-between" mt="4em" p="1em 2em" width="100%" > 
                <Box fontSize="1.2em" whiteSpace="nowrap"><h1>{name}</h1></Box>
                    {displayMode == "flat-list"? <LinksFlatList links={linksList}/>
                        : <LinksBurderMenu links={linksList}/>}
            </Flex>
        </nav>
}