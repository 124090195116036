import { Flex, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { loadSiteLook } from "../../../../sitelookmanagement/SiteLookManagement";

export function SiteText(){
    const [headText, setHeadText] = 
        useState("Loading head text...");

    useEffect(() => {
        loadSiteLook().then(res => {
            if(res.success){
                setHeadText(res.data.headerMessage);
            }
        })
    }, [])
    return <Heading textAlign="center" width="100%" fontSize="1.6em" fontWeight="500">{headText}</Heading>
}