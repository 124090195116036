import { axiosInstance } from "../api/axios";
import { ApiResult, ApiSuccessResult, handleError } from "../api/types";


export interface IVoucherIssueResponse{
    voucher: string,
}

export interface IVoucherIssueRequest {
    discount: number;
    currency: string;
    days: number;
    description: string;
}

export interface IVoucherCheckModel{
    voucher: string;   
}

type ResponseCurrency = "$" | "%";

export interface IVoucherCheckReponseModel{
    isValid: boolean;
    reason: string;
    discount: number | null,
    currency: ResponseCurrency | null
}

export interface IActivasionResponse{
    userEmail: string;
    activationDate: string;
}

export interface IVoucherResponseModel{
    id: number;
    voucher: string;
    description: string;
    issueDate: string;
    tillDate: string;
    userActivations: IActivasionResponse[];
    discount: number;
    currency: ResponseCurrency
}

export function loadVouchersFromApi(): Promise<ApiSuccessResult<IVoucherResponseModel[]>>{
    return axiosInstance.get("/Voucher").then(res => {
        return {
            data: res.data as IVoucherResponseModel[],
            success: true
        } as ApiSuccessResult<IVoucherResponseModel[]>
    })
}

export function validateVoucher(requestModel: IVoucherCheckModel): Promise<ApiResult<IVoucherCheckReponseModel>> {
    return axiosInstance.post("/Voucher/Check", requestModel).then(res => {
        return {
            success: true,
            data: res.data as IVoucherCheckReponseModel 
        }
    })
}
export function issueVoucher(model: IVoucherIssueRequest): Promise<ApiResult<IVoucherIssueResponse>>{
    return handleError(axiosInstance.post("/Voucher/Issue", model).then((res) => {
        return {
            success: true,
            data: res.data as IVoucherIssueResponse
        } as ApiResult<IVoucherIssueResponse>
    }));
}

export function deleteVoucherApiAction(voucherId: number): Promise<ApiResult<{}>>{
    return handleError(axiosInstance.delete(`/Voucher/${voucherId}`).then(() => {
        return {
            success: true,
            data: {}
        };
    }));
}


export interface IUpdateVoucherDescriptionModel{
    voucherId: number;
    voucherDescription: string;
}
export function updateVoucherDescription(voucherUpdateModel: IUpdateVoucherDescriptionModel): Promise<ApiResult<{}>>{
    return handleError(axiosInstance.post(`/Voucher/description`, voucherUpdateModel).then(() => {
        return {
            success: true,
            data: {}
        };
    }));
}