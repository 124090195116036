import { axiosInstance } from "../api/axios";
import { ApiResult, ApiSuccessResult, ErrorsResult, NoType, handleError } from "../api/types";
import { ICategory } from "../components/Main/components/GoodsPage/components/CategoryList/CategoriesList";


export interface IUpdateCategoryApiModel{
    id: number;
    name: string;
}

export interface ICreateCategoryApiModel
{
    name: string;
}

export function updateCategoryName(update: IUpdateCategoryApiModel): Promise<ApiResult> {
    return handleError(axiosInstance.patch("/Categories", update).then(res => {
        return {
            success: true,
            data: {} as unknown
        } as ApiSuccessResult;
    }));
}

export function loadCategories(): Promise<ApiSuccessResult<ICategory[]>>{
    return axiosInstance.get("/Categories").then((res) => {
       return {
            success: true,
            data: res.data as ICategory[]
      };
    });
}

export function createCategory(category: ICreateCategoryApiModel): Promise<ApiResult>{
    return handleError(axiosInstance.post("/Categories", category).then(res => {
        return {
            success: true,
            data: {} as unknown,
        } as ApiSuccessResult
    }));
}


export function deleteCategory(categoryId: number): Promise<ApiResult>{
    return handleError(axiosInstance.delete(`/Categories/${categoryId}`).then(succ => {
        return {
            success: true,
            data: { noType: true } as NoType
        } as unknown as ApiSuccessResult;
    }));
}