import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { useBalanceUpdateModal } from "../stores/balanceUpdateModal";
import { FieldValues, useForm } from "react-hook-form";
import { priceValidator } from "../shared/validators/SharedValidator";
import { useCallback, useState } from "react";
import { updateBalance } from "../balanceMangement/BalanceMangement";
import SelectMerchant from "../components/Main/components/Cart/SelectMerchant";


const amountFieldName: string = "amount";
export function BalanceTopUpModal(){
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [merchant, setMerchant] = useState('plisio');

    const [responseErrors, setResponseErrors] = useState<string[]>([]);
    const {register, formState: {errors}, setError, handleSubmit} = useForm();
    const isDisplayed = useBalanceUpdateModal(state => state.isDisplayed);
    const hide = useBalanceUpdateModal(state => state.hide);


    const submitHandler = useCallback((model: FieldValues) => {
        setResponseErrors([]);
        const fieldValue = model[amountFieldName]
        const parsedAmountValue = parseFloat(fieldValue);
    
        updateBalance({
            amount: parsedAmountValue,
            merchant: merchant
        }).then(resp => {
            if(resp.success){
                hide({
                    operationStatus: "Confirmed",
                    link: resp.data.paymentUrl
                })
            }else{
               setResponseErrors(resp.data.errors);
            }
        })
    }, [merchant])
    const handleClose = () => {
        hide({
            operationStatus: "Rejected"
        })
        onClose();
    }
    if(!isOpen && isDisplayed){
        onOpen();

    }else if(isOpen && !isDisplayed){
        handleClose()
    }

    
    return <>
        <Modal isOpen={isOpen} size="2xl" onClose={handleClose} isCentered>
            <ModalOverlay/>
            <ModalContent>
                <Box padding="1em 0">
                <ModalHeader>Choose balance update amount</ModalHeader>

                <ModalBody>
                    <form onSubmit={handleSubmit(submitHandler)}>
                    <Stack margin="0 auto" spacing="1em" padding="1em 0" alignItems="center">
                            <FormControl isInvalid={!!errors[amountFieldName]} isRequired>
                                <Flex alignItems="center" width="70%">
                                    <FormLabel whiteSpace="nowrap">Update amount: </FormLabel>
                                    <Input {...register(amountFieldName, {
                                        validate: priceValidator
                                    })}type="text"/>
                                </Flex>
                                {!!errors[amountFieldName] && <FormErrorMessage>
                                        {errors[amountFieldName].message as string}
                                    </FormErrorMessage>}
                            </FormControl>
                        <Stack spacing="1em">
                            {responseErrors.length > 0 && responseErrors.map((err, index) => <Text key={index} fontSize="1.2em" color="re">
                                {err}
                                </Text> )}
                        </Stack>
                        <SelectMerchant setMerchant={setMerchant}/>
                        <Button type="submit" colorScheme="green">Top Up</Button>
                    </Stack>
                    </form>
                </ModalBody>      
            </Box>
            </ModalContent>
        </Modal>
    </>

}