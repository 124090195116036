import { Text, Modal, ModalBody, Link, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Flex, HStack } from "@chakra-ui/react"
import { useInformationModal } from "../stores/informationtModal"
import { useResizeOnChange } from "../hooks/useResizeOnChange";


type DisplayState = "Squashed" | "Full"
export function InformationModal(){
    const {isOpen, onOpen, onClose} = useDisclosure();
    const isDisplayed = useInformationModal(state => state.isDisplayed);
    const hide = useInformationModal(state => state.hide);
    const modalData = useInformationModal(state => state.modalData);


    const displayState = useResizeOnChange<DisplayState>((width) => width < 700? "Squashed": "Full");
    const handleClose = () => {
        hide();
        onClose();
    }
    if(!isOpen && isDisplayed){
        onOpen();

    }else if(isOpen && !isDisplayed){
        handleClose()
    }
    if(modalData === null){
        return <></>;
    }
    return <>
        <Modal isOpen={isOpen} size={displayState == "Squashed"? "lg" : "2xl"} onClose={handleClose} isCentered>
            <ModalOverlay/>
            <ModalContent>
                {!!modalData.headerName && <ModalHeader>{modalData.headerName}</ModalHeader>}

                <ModalBody>
                    {modalData.component}                    
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
}
