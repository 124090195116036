import { axiosInstance } from "../api/axios";
import { ApiSuccessResult } from "../api/types";
import { ConfirmedPaymentModel, IBasePaymentModel, PaymentModel, PaymentStatus, PaymentTypes, PendingPaymentModel, PurchasedProductsModel } from "../components/Main/components/Purchases/Purchases";



type ResponsePaymentStatus = "WaitForPayment" | "Confirmed" | "Rejected";
type ResponsePaymentType = "BalanceUpdate" | "ProductPaymentFromBalance" | "ProductPaymentFromWallet";


export interface IResponseProduct {
    productName: string;
    productPrice: number;
    productPhotoUrl: string;
}
interface IPurchasedProductResponseModel{ 
    product: IResponseProduct;
    attachedItems: string[];
}

export interface IPaymentResponseModel{
    userEmail: string;
    id: number;
    dateOfCreation: string;
    dateOfConfirmation: string | null;
    paymentAmount: number;
    paymentStatus: ResponsePaymentStatus
    paymentType: ResponsePaymentType,
    paymentUrl: string | null;
    purchasedProducts: IPurchasedProductResponseModel[] | null; 
}


export function loadPayments(): Promise<ApiSuccessResult<PaymentModel[]>>{
    return axiosInstance.get("/Payment").then(res => {
        return {
            success: true,
            data: mapPaymentResponse(res.data as IPaymentResponseModel[])
        } as ApiSuccessResult<PaymentModel[]>;
    });
}


function mapPaymentResponse(paymentResponse: IPaymentResponseModel[]): PaymentModel[]{
    const mapStatus = (status: ResponsePaymentStatus): PaymentStatus =>{
        if(status == "Confirmed"){
            return "Confirmed";
        }else if(status == "Rejected"){
            return "Rejected";
        }else if(status == "WaitForPayment"){
            return "WaitingForPay"
        }
        throw new Error("Unknown payment status");
    }

    const mapPaymentType = (type: ResponsePaymentType): PaymentTypes => {
        if(type == "BalanceUpdate"){
            return "BalanceTopUp";
        }else if(type == "ProductPaymentFromBalance"){
            return "PaymentFromBalance"
        }else if(type == "ProductPaymentFromWallet"){
            return "PaymentFromWallet";
        }

        throw new Error("unknown payment type");
    }
    return paymentResponse.map((responseModel) => {
        const mappedStatus = mapStatus(responseModel.paymentStatus);  
        const mappedType = mapPaymentType(responseModel.paymentType);
        const baseModel: IBasePaymentModel<PaymentStatus, PaymentTypes> = {
            dateOfCreation: new Date(Date.parse(responseModel.dateOfCreation)),
            dateOfConfirmation: responseModel.dateOfConfirmation !== null? new Date(Date.parse(responseModel.dateOfConfirmation)):
                                     responseModel.dateOfConfirmation,
            paymentAmount: responseModel.paymentAmount,
            status: mappedStatus,
            type: mappedType,
            email: responseModel.userEmail,
            paymentId: responseModel.id
        };

        if(baseModel.status == "Confirmed" 
            && (baseModel.type == "PaymentFromBalance"
                || baseModel.type == "PaymentFromWallet"))
        {
            //Means that we should add products to response
            const mappedPurchasedProducts = responseModel!.purchasedProducts!.map(purchasedProductModel => ({
                product: {
                    productName: purchasedProductModel.product.productName,
                    productPhotoUrl: purchasedProductModel.product.productPhotoUrl,
                    productPrice: purchasedProductModel.product.productPrice
                },
                attachedItems: purchasedProductModel.attachedItems
            }));

            const resultModel: ConfirmedPaymentModel<"PaymentFromBalance" | "PaymentFromWallet", PurchasedProductsModel> = {
                ...baseModel as IBasePaymentModel<"Confirmed", "PaymentFromBalance" | "PaymentFromWallet">,
                products: mappedPurchasedProducts,
            }
            return resultModel;
        }
        if(baseModel.status == "WaitingForPay" 
            && (baseModel.type == "BalanceTopUp" || baseModel.type == "PaymentFromWallet")){
                const resultModel: PendingPaymentModel<"PaymentFromWallet" | "BalanceTopUp"> = {
                    ...baseModel as IBasePaymentModel<"WaitingForPay", "PaymentFromWallet" | "BalanceTopUp">,
                    url: responseModel.paymentUrl!
                };
                return resultModel;
            }

        return baseModel as PaymentModel;
    });
}