export const priceValidator = (field: string, model: unknown): string | undefined => {
    for(let i = 0; i < field.length; i++){
        const targetChar = field.charAt(i);
        if(!(targetChar >= '0' && targetChar <= '9' || targetChar == '.')){
            return "Field should be a dot separated float number"
        }
    }
    const parsedFiled = parseFloat(field);
    if(parsedFiled <= 0){
        return "Field should be greater than zero";
    }
    return undefined;
}

export const passwordSharedValidation = {
    pattern: /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,12}/g,
    errorMessage: "Password should be between 6 and 12 letters and should contain at least one number an at least one upercase letter"
};