import { Box, HStack, Text } from "@chakra-ui/react"
import { ISailItem, ISailItemWithCategory } from "./SailItems";
import { ICategory } from "../CategoryList/CategoriesList";
import { SailItemImage } from "./SailIttemImage";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../../../../../customtables/src/table";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { createDatailedProductNavigation } from "../../../DetailProduct/DatailedProduct";

export interface ISailItemActionDescription{
    actionName: string,
    actionComponent: (sailItem: ISailItem) => JSX.Element
};

type SailItemsInput = ISailItemWithCategory[] | ISailItem[]

function isSailItemsWithCategory(sailItem: SailItemsInput): sailItem is ISailItemWithCategory[]{
    return Array.isArray(sailItem) && (sailItem as ISailItemWithCategory[])[0]?.category !== undefined;
}
export const SailItemsTable = ({sailItems, actionDescriptions}: {sailItems: SailItemsInput, actionDescriptions: ISailItemActionDescription[]}) => {
    const navigateToPath = useNavigate();

    const sailItemClickHandler = useCallback((sailItem: ISailItem) => {
        const targetNavigation = createDatailedProductNavigation(sailItem.id);
        navigateToPath(targetNavigation);
    }, [navigateToPath]);

    const createCategoryRow = (category: ICategory, key: number) => <Tr className="category" backgroundColor="blue.300" key={key} textColor="White">
                <Td colSpan={4} textAlign="center">
                    <Text textTransform="uppercase" fontSize="1.2em" fontWeight="600">
                        {category.name}
                    </Text>
                </Td>
            </Tr>;


    
    const createItemRow =(item: ISailItem, key: number) => <Tr key={key}>
                            <Td><SailItemImage sailItem={item} onLabelClick={() => sailItemClickHandler(item)}/></Td>
                            <Td textAlign="center">{item.availableCount}</Td>
                            <Td textAlign="center">{item.priceString}</Td>
                            <Td textAlign="center" width="20%">
                                <HStack 
                                        flexWrap="wrap"
                                        minWidth="160px"
                                        justifyContent="center"
                                        spacing="1em"
                                        width="100%">
                                    {actionDescriptions.map((description, index) => <Box key={index}>
                                        {description.actionComponent(item)}
                                        </Box>)}
                                </HStack>
                            </Td>
                        </Tr>;

    const mapSailItems = (): JSX.Element[] => {
        if(isSailItemsWithCategory(sailItems)){
            let iterationIndex = 0;
            return sailItems.flatMap((sailItemWithCategory) => {
                    const categoryRow = createCategoryRow(sailItemWithCategory.category, iterationIndex);
                    const sailItemsRows = sailItemWithCategory.items.map((sailItem, sailItemIndex) => 
                                    createItemRow(sailItem, iterationIndex + sailItemIndex + 1)
                                    );
                    iterationIndex += sailItemsRows.length + 1;
                    return [categoryRow, sailItemsRows].flat();         
                });
        }
        return sailItems.map((sailItem, index) => createItemRow(sailItem, index));
    }


    const rendedredRows = mapSailItems();

 
    return <Box width="100%" borderRadius="12px" border="0.1em solid" borderColor="blackAlpha.400" padding="0.1em">
            <Table className="responsiveTable" variant="simple">
                <Thead>
                    <Tr>
                        <Th fontSize="1em" textAlign="left">Item name</Th>
                        <Th fontSize="1em" width="10%" textAlign="center">Available</Th>
                        <Th fontSize="1em" width = "15%" textAlign="center">Price</Th>
                        <Th fontSize="1em" textAlign="center" >Actions</Th>
                    </Tr>
                </Thead>
                <Tbody fontSize="1.2em">
                    {rendedredRows}
                </Tbody>
            </Table>
    </Box>
}