import { FormDefinition, GenericForm, createRegistrationName } from "./GenericForm";
import { registerUser } from "../../identiyManagement/IdentityManagement";
import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { defaultFormOutlineStyles } from "./formStyles";
import { passwordSharedValidation } from "../../shared/validators/SharedValidator";

type RegisterModel = {
    email: string;
    password: string;
    againPassword: string;
}


const registerFormDefinition: FormDefinition<RegisterModel> = {
    email: {
        type: "email",
        rules: {
            required: true,
            regexValidation: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                errorMessage: "Should be passed valid email"
            }
        },
        label: "Email:",
        placeHolder: "youremail@yourdomain",
        width: "300px"
    },
    password: {
        type: "password",
        rules: {
            required: true,
            regexValidation: {
                    value: passwordSharedValidation.pattern,
                    errorMessage: passwordSharedValidation.errorMessage,
            }
        },
        label: "Password: ",
        width: "300px"
    },
    againPassword: {
        type: "password",
        rules: {
            required: true,
            validate(itemValue, formValue) {
                const fieldName = createRegistrationName("password", 0);
                const targetValue = (formValue as any)[fieldName]
                if(targetValue != itemValue){
                    return "Passwords should match";
                }
                return undefined;
            },
        },
        label: "Retype password: ",
        width: "300px"
    }
};



const RegisterForm = ({afterSubmitAction}: {afterSubmitAction: () => void}) => GenericForm<RegisterModel>({    outlineStyles: {...defaultFormOutlineStyles, width: "100%"},
    formActions: [
    {
        color: "green",
        text: "Register",
        isSubmit: true,
        width: "10vh"
    },
    ],
    afterSubmitAction: afterSubmitAction,
    formDefinition: registerFormDefinition,
    formLabel: "Register",
    submitAction: (model: RegisterModel) =>{  
        return registerUser(model);
    }
});




export function Register(){
    const navigation = useNavigate();
    return <Flex justifyContent="center" width="100%" paddingTop="4em">
        <RegisterForm afterSubmitAction={() => navigation("/login")}/></Flex>;
}