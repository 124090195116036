import { create } from "zustand";
import { ISailItem, ISailItemWithCategory } from "../components/Main/components/GoodsPage/components/SailItems/SailItems";

type SailItemDiscardedResult = {
    reason: "Discarded"
}

type SailItemSelectedResult = {
    reason: "Selected",
    countOfItems: number
};


type ChooseResult = SailItemSelectedResult | SailItemDiscardedResult;

type SailItemsModalState= {
    isDisplayed: boolean, 
    sailItemId: null | number,
    chooseResult: ChooseResult | null,
    readonly display: (sailItemID: ISailItem['id']) => void,
    readonly hide: (itemsCount?: number) => void,
    readonly clearResult: () => void
}
export const useItemsCountModalStore = create<SailItemsModalState>((set, get) => (
    {
        isDisplayed: false,
        chooseResult:  null,
        sailItemId: null,
        display: (id: ISailItem['id']) => {
            if(!get().isDisplayed){
                set(() => ({isDisplayed: true, chooseResult: null, sailItemId: id}));
            }
        },
        hide: (itemsCount?: number) => {
            const result: ChooseResult = itemsCount? {
                reason: "Selected",
                countOfItems: itemsCount
            }: {
                reason: "Discarded"
            };
            if(get().isDisplayed){
                set(() => ({isDisplayed: false, chooseResult: result}));
            }
        },
        clearResult: () => (set(() => ({chooseResult: null})))
    }
))

