import {Box, Heading, Stack, Text} from "@chakra-ui/react";
import {FormDefinition, GenericForm} from "../../../../Forms/GenericForm";
import {ApiSuccessResult} from "../../../../../api/types";
import {useEffect, useState} from "react";
import {loadSiteLook, updateSiteLook} from "../../../../../sitelookmanagement/SiteLookManagement";


export interface ISiteLookModel {
    header: string;
}

const siteLookFormDefinition: FormDefinition<ISiteLookModel> = {
    header: {
        label: "File header text",
        type: "textarea",
        rules: {
            required: true
        },
        width: "50vw"
    }
}

export const SiteCreationForm = ({reloadAfterSubmit, siteLookModel}:
                                     { reloadAfterSubmit: () => void, siteLookModel: ISiteLookModel }) => GenericForm({
    initialValue: siteLookModel,
    formDefinition: siteLookFormDefinition,
    afterSubmitAction: reloadAfterSubmit,
    direction: "row",
    validationErrorsPosition: "after-actions-new-row",
    submitAction: (model) => {
        return updateSiteLook({
            headerMessage: model.header
        });
    },
    formActions: [
        {
            color: "green",
            text: "Update",
            isSubmit: true,
            width: "100px"
        }
    ]
});

export function SiteDisplay() {
    const [siteLookModel, setSiteLookModel] = useState<ISiteLookModel | null>(null);

    const loadSiteLookModel = () => {
        loadSiteLook().then(siteDisplay => {
            if (siteDisplay.success) {
                const internalModel: ISiteLookModel = {
                    header: siteDisplay.data.headerMessage
                };
                console.log('reloading with', internalModel)
                setSiteLookModel(internalModel);
            }
        })
    }
    useEffect(() => {
        loadSiteLookModel()
    }, [])


    console.log(siteLookModel);
    const siteContent = siteLookModel ?
        <Box width="80%">
            <SiteCreationForm reloadAfterSubmit={() => loadSiteLookModel()} siteLookModel={siteLookModel}/>
        </Box>
        : <Text>Loading preferences...</Text>;

    return <Stack alignItems="center" paddingTop="0.5em">
        <Heading marginBottom="0.5em">Site Display</Heading>
        {siteContent}
    </Stack>
}