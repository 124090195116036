import { Flex, FormControl, FormLabel, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react"

export const SailItemCount = ({maxValue, updateCount, count, minValue, showText, label}:
     {  
        count: number,
        maxValue: number,
        updateCount: (newCount: number) => void,
        minValue: number,
        showText: boolean,
        label?: JSX.Element}) => {
        
    label = <FormLabel whiteSpace="nowrap">Quantity:</FormLabel>;
    const handler = (str: string, newCount: number) => {
        if(!isNaN(newCount)){
            if(newCount == count) return;
            if(newCount > maxValue){
                updateCount(maxValue);
            }
            updateCount(newCount)
        }};

    return <FormControl>
        <Flex alignItems="center">
            {showText && label}
            <NumberInput onKeyDown={() => false} onChange={handler} width="100%"  value={count} min={minValue} max={maxValue}>
                <NumberInputField/>
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </Flex>
    </FormControl>
}