import { Button,Text, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Flex, HStack } from "@chakra-ui/react"
import { ConfirmationResult, useConfirmationModal } from "../../stores/confirmationModal";
import { useCallback } from "react";


export function ConfirmationModal(){
    const {isOpen, onOpen, onClose} = useDisclosure();
    const isDisplayed = useConfirmationModal(state => state.isDisplayed);
    const confirmationText = useConfirmationModal(state => state.confirmationText);
    const hide = useConfirmationModal(state => state.hide)


    const handleClose = useCallback(() => {
        onClose();
        hide("rejected");
    }, []);

    const resolve = useCallback((state: Exclude<ConfirmationResult, "waitingToSet">) => {
        onClose();
        hide(state);
    }, []);
    if(!isOpen && isDisplayed){
        onOpen();

    }else if(isOpen && !isDisplayed){
        handleClose()
    }

    
    return <>
        <Modal isOpen={isOpen} size="xl" onClose={handleClose} isCentered>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Confirm operation</ModalHeader>

                <ModalBody>
                    <Text width="100%" padding="1.5em 0" fontSize="1.4em">{confirmationText}</Text>
                </ModalBody>

                <ModalFooter>
                    <Flex width="100%" justifyContent="center" >
                        <Button marginRight="3em" onClick={() => resolve("confirmed")}colorScheme="whatsapp">Confirm</Button>
                        <Button paddingInline="1.5em" onClick={() => resolve("rejected")}colorScheme="red">Reject</Button>  
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}