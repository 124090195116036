import { Box, Button, Flex, Text, FormControl, FormLabel, Input, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, useDisclosure, FormErrorMessage, Textarea } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useEditVoucherModalStore } from "../stores/editVoucherModal";
import { updateVoucherDescription } from "../vouchermanagement/VoucherManagement";


const voucherField = "voucherField"
export function EditVoucherTextModal(){
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [responseErrors, setResponseErrors] = useState<string[]>([]);
    const {register, formState: {errors}, handleSubmit, setValue} = useForm();
    const isDisplayed = useEditVoucherModalStore(state => state.isDisplayed);
    const hide = useEditVoucherModalStore(state => state.hide);
    const requestedVoucher = useEditVoucherModalStore(state => state.requestedVoucher);
    const submitHandler = useCallback((model: FieldValues) => {
        if(!requestedVoucher) return;
        console.log('submit invoked');
        setResponseErrors([]);
        const newDescription = model[voucherField];
        updateVoucherDescription({
            voucherDescription: newDescription,
            voucherId: requestedVoucher?.id
        }).then(res => {
            console.log(res);
            if(res.success){
                hide("updated");
            }else{
                setResponseErrors(res.data.errors);
            }
        })
    }, [])

    useEffect(() => {
        if(requestedVoucher){
            setValue(voucherField, requestedVoucher.description);
        }
    }, [requestedVoucher]);
    const handleClose = () => {
        hide("rejected");
        onClose();
    }
    if(!isOpen && isDisplayed){
        onOpen();

    }else if(isOpen && !isDisplayed){
        handleClose()
    }

    
    return <>
        <Modal isOpen={isOpen} size="2xl" onClose={handleClose} isCentered>
            <ModalOverlay/>
            <ModalContent>
                <Box padding="1em 0">
                <ModalHeader>Edit voucher metadata</ModalHeader>

                <ModalBody>
                    <form onSubmit={handleSubmit(submitHandler)}>
                    <Stack margin="0 auto" spacing="1em" padding="1em 0" alignItems="center">
                            <FormControl isInvalid={!!errors[voucherField]} isRequired>
                                <Flex alignItems="center" width="70%">
                                    <FormLabel whiteSpace="nowrap">Description: </FormLabel>
                                    <Textarea {...register(voucherField, {
                                        required: true
                                    })} />
                                </Flex>
                                {!!errors[voucherField] && <FormErrorMessage>
                                        {errors[voucherField].message as string}
                                    </FormErrorMessage>}
                            </FormControl>
                        <Stack spacing="1em">
                            {responseErrors.length > 0 && responseErrors.map((err, index) => <Text key={index} fontSize="1.2em" color="re">
                                {err}
                                </Text> )}
                        </Stack>
                        <Button type="submit" colorScheme="green">Change</Button>
                    </Stack>
                    </form>
                </ModalBody>      
            </Box>
            </ModalContent>
        </Modal>
    </>

}