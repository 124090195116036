import { Button, Flex, HStack, Heading, Stack, Text} from "@chakra-ui/react";
import { IVoucherIssueRequest, IVoucherIssueResponse, IVoucherResponseModel, deleteVoucherApiAction, issueVoucher, loadVouchersFromApi } from "../../../../../vouchermanagement/VoucherManagement";
import { FormActions, FormDefinition, GenericForm } from "../../../../Forms/GenericForm";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Table, Td, Th,  Thead, Tr , Tbody  } from "../../../../../customtables/src/table";
import { stringifyDate } from "../../../../../shared/DateUtils";
import { useInformationModal } from "../../../../../stores/informationtModal";
import { useEditVoucherModalStore } from "../../../../../stores/editVoucherModal";



type Currecy = "%" | "$";

type VoucherForm = {
    discount: number;
    currency: Currecy,
    expiryDate: string,
    voucherDesctiption: string;
}


const voucherForm: FormDefinition<VoucherForm> = {
    currency: {
        label: "Currency:",
        type: "select",
        values: ["$", "%"],
        rules: {
            required: true
        },
        mapOptionText: (currencyValue) => currencyValue,
        mapValue: (currencyValue) => currencyValue
    },
    discount: {
        label: "Discount",
        type: "text",
        rules: {
            required: true,
            validate: (discount, formFields) => {
                const targetDiscount = parseFloat(discount);
                if(formFields['currency'] == "%"){
                    if(targetDiscount <= 0 || targetDiscount > 100){
                        return "Discount in percents should be between 1 and 100";
                    }
                }else{
                    if(targetDiscount <= 0) {
                        return "Discount should be greater than 0";
                    }
                }
                return undefined;
            }
        },
    },
    expiryDate: {
        label: "Expiry Date:",
        rules: {
            required: true,
            validate: (date) => {
                const difWithNow = Date.parse(date) - Date.now();
                if(difWithNow < 0){
                    return "You passed already expired voucher";
                }
                return undefined;
            }
        },
        type: "date",
    },
    voucherDesctiption: {
        label: "Voucher description:",
        type: "textarea",
        rules: {}
    }
}

const formActions: FormActions = [{
    text: "Issue",
    color: "green",
    isSubmit: true,
    width: "200px"
}] 

const VoucherGenerateForm = ({afterSubmitAction}: {afterSubmitAction: (apiMode: IVoucherIssueResponse) => void}) => GenericForm({
    direction: "row",
    formDefinition: voucherForm,
    formActions: formActions,
    validationErrorsPosition: "after-actions-new-row",
    afterSubmitAction: afterSubmitAction,
    submitAction: (model) => {
        var differenceInMiliseconds = Date.parse(model.expiryDate) - Date.now();
        var differenceInDays = Math.trunc(differenceInMiliseconds / 1000 / 60 / 60 / 24);
        const requestModel: IVoucherIssueRequest = {
            currency: model.currency,
            days: differenceInDays,
            discount: model.discount,
            description: model.voucherDesctiption
        };
        return issueVoucher(requestModel);
    }
})

export interface IActivasion{
    userEmail: string;
    activasionDate: Date;
}
export interface IVoucher{
    id: number;
    voucher: string;
    description: string;
    issueDate: Date;
    tillDate: Date;
    activasions: IActivasion[]
    discount: string;
}

const parseDateFromString = (dateString: string): Date => {
    console.log(dateString);
    const paresedData = Date.parse(dateString);
    const resultDate = new Date(paresedData);
    console.log(resultDate)
    return new Date(Date.parse(dateString))
}

function mapVouchersFromApi(vouchersFromApi: IVoucherResponseModel[]): IVoucher[]{
    console.log(vouchersFromApi);
    return vouchersFromApi.map((voucher) => {
        return {
            description: voucher.description,
            discount: voucher.discount + voucher.currency,
            id: voucher.id,
            issueDate: parseDateFromString(voucher.issueDate),
            tillDate: parseDateFromString(voucher.tillDate),
            activasions: voucher.userActivations.map(apiActivasion => {
                return {
                    activasionDate: parseDateFromString(apiActivasion.activationDate),
                    userEmail: apiActivasion.userEmail
                } as IActivasion
            }),
            voucher: voucher.voucher,
        } as IVoucher;
    })
}

export function ActivasionList({activasions}: {activasions: IActivasion[]}){
    const defaulStyles = {"textAlign": "center" as "center"}
    return <Stack paddingBottom="2em" spacing="2em" alignItems="center">
                <Table>
                <Thead>
                    <Tr>
                        <Th {...defaulStyles}>User Email</Th>
                        <Th {...defaulStyles}>Activasion Date</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {activasions.map((activasion, index) => <Tr key={index}>
                        <Td {...defaulStyles}>{activasion.userEmail}</Td>
                        <Td {...defaulStyles}>{stringifyDate(activasion.activasionDate)}</Td>
                    </Tr>)}
                </Tbody>
            </Table>
            {activasions.length == 0 && <Text fontSize="1.4em">Voucher isn't activated by any of users</Text>}
        </Stack>
}

export function Voucher(){
    const showInformationModal = useInformationModal(modal => modal.show);
    const [vouchers, setVouchers] = 
        useState<IVoucher[] | null>();
    const loadVouchers = useCallback(() => {
        loadVouchersFromApi().then(succ => {
                setVouchers(mapVouchersFromApi(succ.data))
        });
    }, [setVouchers]);

    useEffect(() => {
        loadVouchers();
    }, [loadVouchers])


    const updateVoucher = useCallback(() => {
            loadVouchers();
    }, [loadVouchers])

    const deleteVoucher = useCallback((voucher: IVoucher) => {
        deleteVoucherApiAction(voucher.id).then(() => loadVouchers());
    }, [loadVouchers]);

    const listVoucherActivations = useCallback((voucher: IVoucher) => {
        showInformationModal({
            headerName: "Voucher activasions",
            component: <ActivasionList activasions={voucher.activasions}/>
        })
    }, [showInformationModal])
   return <Stack spacing="4em">
            <VoucherGenerateForm afterSubmitAction={loadVouchers}/>
            <Stack alignItems="center" spacing="2em">
                <Heading>Active vouchers</Heading>
                {!!vouchers? <VoucherTable 
                                vouchers={vouchers}
                                reloadVouchers={updateVoucher} 
                                deleteVoucher={deleteVoucher}
                                listActivations={listVoucherActivations}></VoucherTable> : 
                    <Text>Loadig Vouchers...</Text>}
            </Stack>
          </Stack>
}

type VoucherTableProps = { 
    vouchers: IVoucher[], 
    deleteVoucher: (voucher: IVoucher) => void,
    reloadVouchers: () => void,
    listActivations: (voucher: IVoucher) => void,
    }

    
export function VoucherTable({
    vouchers, 
    deleteVoucher, 
    reloadVouchers,
    listActivations}: VoucherTableProps){

    const showEditVoucher = useEditVoucherModalStore(state => state.show);
    const result = useEditVoucherModalStore(state => state.result);
    const clearResult = useEditVoucherModalStore(state => state.clearResult);
    if(result == "updated"){
        clearResult();
        reloadVouchers();
    }


    const tableHeadings = useMemo(() => [
        "Discount",
        "Voucher",
        "Issue Date",
        "Till Date",
        "Description",
        "Action"
    ], []);

    const defaultRowStyles = {
        "textAlign": "center" as "center"
    }


    
    const createVoucherRow = useCallback((voucher: IVoucher, index: number): JSX.Element => {
        const voucherActions = createVoucherActions(voucher, deleteVoucher, listActivations, (voucher) => {
            showEditVoucher(voucher);
        })
        const actionPart = <VoucherAction voucherActions={voucherActions}/>
        return <Tr key={index}>
            <Td {...defaultRowStyles}>{voucher.discount}</Td>
            <Td {...defaultRowStyles}>{voucher.voucher}</Td>
            <Td {...defaultRowStyles}>{stringifyDate(voucher.issueDate)}</Td>
            <Td {...defaultRowStyles}>{stringifyDate(voucher.tillDate)}</Td>
            <Td {...defaultRowStyles}>{voucher.description}</Td>
            <Td {...defaultRowStyles}>{actionPart}</Td>
        </Tr>
    }, []);
    return <Table width="100%" variant="simple">
                <Thead>
                    <Tr>
                        {tableHeadings.map((headingName, index: number) => <Th {...defaultRowStyles} key={index}>{headingName}</Th>)}
                    </Tr>       
                </Thead>
                <Tbody>
                    {vouchers.map((voucher: IVoucher, index: number) => 
                            createVoucherRow(voucher, index))}
                </Tbody>
            </Table>
}



type VoucherAction = {
    label: string;
    action: () => void;
    colorSheme: "red" | "green" | "blue"
}

const createVoucherActions = (voucher: IVoucher, 
                              deleteVoucher: (vocher: IVoucher) => void, 
                              updateVoucher: (voucher: IVoucher) => void,
                              editVoucher: (voucher: IVoucher) => void): VoucherAction[] => 
{
    const bindedDelete = deleteVoucher.bind(null, voucher);
    const bindedUpdate = updateVoucher.bind(null, voucher);
    const bindedEdit = editVoucher.bind(null, voucher);
    return [
        {
            colorSheme: "red",
            label: "Delete",
            action: bindedDelete
        },
        {
            colorSheme: "green",
            label: "List activations",
            action: bindedUpdate
        },
        {
            colorSheme: "blue",
            label: "Edit",
            action: bindedEdit
        }
    ];
}


export function VoucherAction({voucherActions}: {voucherActions: VoucherAction[]}){
    return <HStack alignItems="center" justifyContent="center">
        {voucherActions.map((vouceherAction ,index) => <Button key={index} 
                                                               onClick={vouceherAction.action} 
                                                               colorScheme={vouceherAction.colorSheme} >
            {vouceherAction.label}
            </Button>)}
    </HStack>
}
