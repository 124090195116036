import { useEffect, useState } from "react";
import { loadAuthenticatedUserRole } from "./IdentityManagement";
import { useIdentityStore } from "../stores/userIdentity";


export const useAuthorization= () => {
    const userAuthState = 
            useIdentityStore((identityState) => identityState.userAuthState);
    const logout =
            useIdentityStore((identityState) => identityState.logout);
    const [userRole, setUserRole] = useState<"Admin" | "User" | null>(null);
    useEffect(() => {
        if(userAuthState.isUserAuthenticated){
            loadAuthenticatedUserRole()
                .then(res => {
                    if(res.success){
                        setUserRole(res.data.role);
                    }else{
                        logout();   
                    }
                })
        }
    }, [userAuthState, logout]);

    if(userAuthState.isUserAuthenticated){
        return {
            isAuthenticated: true,
            email: userAuthState.userEmail,
            userRole
        }
    }

    return {isAuthenticated: false, email: null, userRole};
}