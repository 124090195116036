import { Flex, Text } from "@chakra-ui/react";
import { useRouteError } from "react-router-dom";
import { getConfiguration } from "../../configuration/Configuration";


export function Fallback(){
    const routerError = useRouteError() as {statusText?: string, message?: string};
    const configuration = getConfiguration();

    const errorText = routerError?.statusText || routerError?.message;

    console.log(routerError);
    return <Flex paddingTop="2em" alignItems="center" justifyContent="center" direction="column" width="100%">
        <Text marginBottom="1em" >Unexpected path</Text>
        {configuration.environemnt.isDevelopment && !!errorText &&
            <Text>{errorText}</Text>}    
    </Flex>
}