
export type NoType = {noType: true}
export interface ApiSuccessResult<TResult=NoType>{
    success: true,
    data: TResult extends NoType? undefined: TResult    
}

export type ApiFaultResult<TResult=ErrorsResult> = {
    success: false,
    data: TResult
}

export type ApiResult<TResult=NoType, TErrorResult=ErrorsResult> = ApiSuccessResult<TResult> | ApiFaultResult<TErrorResult>;

export type ErrorsResult = {
    errors: string[]
}


export function handleError<TResult>(promiseToWrap: Promise<TResult>) {
    return promiseToWrap.catch(err => {
        console.log("In error handler");
        if(err.response){
            return {
                success: false,
                data: err.response.data as ErrorsResult
            } as ApiFaultResult;
        }

        throw new Error("Unhandled request error");
    })
}
