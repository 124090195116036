import { useLoaderData, useSearchParams } from "react-router-dom";
import { CategoryList, ICategory } from "./components/CategoryList/CategoriesList";
import { ISailItem, ISailItemWithCategory, SailItems } from "./components/SailItems/SailItems";
import { SiteText } from "../SiteText/SiteText";
import { loadSailItemsWithCategoryId, mapSailItem } from "../../../../sailitemmanagement/SailItemsManagement";
import { loadCategories } from "../../../../categoryManagement/CategoryManagement";
import { useCallback } from "react";
import { Stack } from "@chakra-ui/react";

export interface IGoodsLoaderResponse 
{
    categories: ICategory[],
    sailItems: ISailItemWithCategory[]
}
export function Goods(){
    const [seacrhParams, setSearchParams] = useSearchParams();
    const categoryId = getCategoryIdFromUrlParams(seacrhParams);
    const updateCategoryId = useCallback((categoryId: number) => {
        setSearchParams({
            'categoryId': mapCatgoryToUrlParams(categoryId)
        });
    }, []);
    const {categories, sailItems} = useLoaderData() as IGoodsLoaderResponse;
    return <Stack spacing="3em" marginTop="2em"> 
        <SiteText/>
        <CategoryList updateSelectCategory={updateCategoryId} selectedCategory={categoryId} categories={categories}/>
        <SailItems sailItems={sailItems}/>
    </Stack>
}

const allCategoriesContstant = -1;

export async function goodsLoader({request}: {request: {url: string}}): Promise<IGoodsLoaderResponse>{
    const requestSearchParams = new URL(request.url).searchParams; 
    const parsedCategoryId = getCategoryIdFromUrlParams(requestSearchParams)
    const categories = await loadCategories().then(res => res.data);
    const sailItems = await loadSailItemsWithCategoryId(parsedCategoryId).then(categoryFetchResult => {
        if(categoryFetchResult.success){
            return categoryFetchResult.data.map(sailItem => mapSailItem(sailItem));
        }
        throw new Error("Fault request to fetch sail items");
        //TODO write error handling
    });
    const categoryIdToSailItems = sailItems.reduce((agregated, sailItem) => {
        return {...agregated,
                [sailItem.categoryId]: [...(agregated[sailItem.categoryId] ?? []), sailItem]
            };
    }, {} as Record<number, ISailItem[]>)


    const preFilter = categories.filter(category => category.id !== -1);
    const targetCategory = parsedCategoryId == -1? preFilter: preFilter.filter(category => category.id === parsedCategoryId) ;
    const sailItemsWithCategory = targetCategory
        .map((category): ISailItemWithCategory => {
        return {
            category,
            items: categoryIdToSailItems[category.id] ?? []
        };
    })
    
    return {
        categories,
        sailItems: sailItemsWithCategory
    };
}


function mapCatgoryToUrlParams(categoryId: number): string{
    if(categoryId == -1) {
        return 'all';
    }
    return categoryId.toString();
}
function getCategoryIdFromUrlParams(params: URLSearchParams): number{
    const categoryId = params.get('categoryId');
    if(categoryId){
        const parsedCategoryId = parseInt(categoryId);
        if(parsedCategoryId){
            return parsedCategoryId;
        }
    }
    return allCategoriesContstant;
}