import { Flex, VStack, Text, } from "@chakra-ui/react";
import { IAnononymusPaymentResult } from "../stores/buyNowModalStore";
import { PaymentLink } from "../shared/PaymentLink";

export function PaymentSuccededModal({paymentResult}: {paymentResult: IAnononymusPaymentResult}) {
    return <VStack spacing="1em" width="100%">
        {paymentResult.email && <Text fontSize="1.4em">Email: {paymentResult.email}</Text>}
        {paymentResult.password && <Text fontSize="1.4em">Password: {paymentResult.password}</Text>}
        <Flex alignItems="center" flexDirection="column">
            <Text whiteSpace="nowrap" marginRight="0.2em" fontSize="1.4em" >Payment link: </Text>
            <PaymentLink paymentLink={paymentResult.paymentUrl}/>
        </Flex>
    </VStack>
}
