import { Link } from "@chakra-ui/react";

export function PaymentLink({paymentLink}:{paymentLink: string}){
    return <Link 
                textAlign="center" 
                target="_blank"
                width="100%"
                wordBreak="break-all"
                href={paymentLink}
                padding="1.5em 0"
                fontSize="1.4em"
                isExternal>{paymentLink}
            </Link>
}