import { useCallback, useState } from "react";
import { useBuyNowModalStore } from "../stores/buyNowModalStore";
import { AddItemToCart } from "../cartmanagement/CartManagement";
import { useItemsCountModalStore } from "../stores/itemscount";
import { PaymentSuccededModal } from "../modals/PaymentSucceedModal";
import { useInformationModal } from "../stores/informationtModal";

export function useAddProductToCartModal() {
    const chooseResult = useItemsCountModalStore(state => state.chooseResult);
    const displayModal = useItemsCountModalStore(state => state.display);
    const clearResult = useItemsCountModalStore(state => state.clearResult);
    const getResult = useCallback(() => {
        return chooseResult;
    }, [chooseResult])
    return {displayModal, resultManagement: {
            getResult,
            clearResult}};
}

export function useBuyProductNow(){
    const productBuyResult = useBuyNowModalStore(state => state.result);
    const showBuyProductNow = useBuyNowModalStore(state => state.show);
    const clearResult = useBuyNowModalStore(state => state.clearResult);
    
    const getResult = useCallback(() => {
        return productBuyResult;
    }, [productBuyResult]);

    return {displayModal: showBuyProductNow, resultManagement: {
        getResult,
        clearResult,
    }}
}


type ProductInfo = { productId: number };
type BuyModalState = { type: "BuyNow" } & ProductInfo
type AddToCartModalState = { type: "AddToCart" } & ProductInfo


type ModalDisplayRequest = BuyModalState | AddToCartModalState;

export type RequestDisplayFunction = (state: ModalDisplayRequest) => void;

type ModalDisplayState = ModalDisplayRequest & {
    isDisplayed: boolean;
}


export function useProductPayment(): {requestDisplay: RequestDisplayFunction}{
    const [displayState, setDisplayState] = useState<ModalDisplayState | null>(null);
    const showInformationModal = useInformationModal(state => state.show);
    const {displayModal: displayCartModal, resultManagement: {
        clearResult: clearCartResult, 
        getResult: getCartResult}} = useAddProductToCartModal();
    const {displayModal: displayBuyNowModal, resultManagement: {
        clearResult: clearBuyNowResult, 
        getResult: getBuyNowResult}} = useBuyProductNow();
    const requestDisplay: RequestDisplayFunction = (state: ModalDisplayRequest) => {
        setDisplayState({...state, isDisplayed: false});
    }
    if(displayState) {
        if(displayState.type === "BuyNow"){
            if(!displayState.isDisplayed){
                displayBuyNowModal(displayState.productId);
                setDisplayState({...displayState, isDisplayed: true})
            }else{
                const displayResult = getBuyNowResult();
                if(displayResult){
                    if(displayResult.state == "requestedAddToCart"){
                        requestDisplay({
                            type: "AddToCart",
                            productId: displayState.productId
                        });
                    }else{ 
                        if(displayResult.state == "succeedPayment"){
                            showInformationModal({
                                headerName: "Payment information",
                                component: <PaymentSuccededModal paymentResult={displayResult.model}/>
                            })
                        }
                        setDisplayState(null);
                    }
                    clearBuyNowResult();
                }
            }
        }else if(displayState.type === "AddToCart"){
            if(!displayState.isDisplayed){
                displayCartModal(displayState.productId);
                setDisplayState({...displayState, isDisplayed: true});
            }else{
                const addToCartResult = getCartResult();
                if(addToCartResult){
                    if(addToCartResult.reason == "Selected"){
                        AddItemToCart(displayState.productId, addToCartResult.countOfItems);
                    }
                    clearCartResult();
                    setDisplayState(null);
                }
            }
        }
    }

    return {requestDisplay};
}