import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Router } from './router';
import { RouterProvider } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import {QueryClient, QueryClientProvider} from "react-query";
import ReactGA from 'react-ga4';

const id = process.env.REACT_APP_GOOGLE_ID ? process.env.REACT_APP_GOOGLE_ID : '';

ReactGA.initialize(id);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

const RootComponent = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/${window.location.pathname + window.location.search}`, title: `${window.location.pathname}` });
    }, [])

  const rootJSX =
      <QueryClientProvider client={queryClient}><ChakraProvider>
                    <RouterProvider router={Router}/>
      </ChakraProvider></QueryClientProvider>


  if(process.env.ENVIRONMENT == "development"){
    return <React.StrictMode>
            {rootJSX}
          </React.StrictMode>
  }
  return rootJSX;
}
root.render(
  <RootComponent/>
)


reportWebVitals();
