import {create} from "zustand";

export interface IInformationModalData{
    headerName?: string;
    component: JSX.Element;
}

export interface IInformationModal{
    modalData: IInformationModalData | null;
    isDisplayed: boolean;
    readonly show: (data: IInformationModalData) => void;
    readonly hide: () => void;
}

export const useInformationModal = create<IInformationModal>((set) => ({
    hide: () => {set(() => ({
        isDisplayed: false,
        modalData: null
    }))},
    isDisplayed: false,
    modalData: null,
    show: (component) => {set(() => (
        {
        isDisplayed: true,
        modalData: component
    }))}
}));