import { Flex, Text, FormControl, FormLabel, Heading, Input, VStack, Button, FormErrorMessage } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { defaultFormOutlineStyles } from "../Forms/formStyles";
import { useMemo, useState } from "react";
import { useRedirectOnAuthenticated } from "../../hooks/useRedirectOnAuthenticated";
import { restorePassword, tryStartPasswordRestore, validatePasswordRestoreCode } from "../../restorePasswordManagement/restorePasswordManagement";
import { passwordSharedValidation } from "../../shared/validators/SharedValidator";
import { useNavigate } from "react-router-dom";
import { useInformationModal } from "../../stores/informationtModal";


export interface IPasswordResoreModel{
    email: string;
    restoreCode: string;
    password: string;
    againPassword: string;
}


const EmailCodeNotification = () => {
    return <Text 
                fontSize="1.4em"
                fontWeight="700"
                padding="1em"
                textAlign="center">
        We have sent you the restore code to your email
        </Text>;
}


type RestoreState = {state: "not-checked"} | 
             {state: "email-checked"} | 
             {state: "restoreCode-checked"};
export function RestorePassowrd(){
    useRedirectOnAuthenticated();
    const showInformation = useInformationModal(state => state.show);
    const navigate = useNavigate();
    const {register,formState: {errors}, handleSubmit } = useForm<IPasswordResoreModel>();
    const [restoreState, setRestoreState] = useState<RestoreState>({
        state: "not-checked"
    });
    const [responseErrors, setErrors] = useState<string[] | null>(null); 

    const onSubmit = (formValues: IPasswordResoreModel ) => {
        if(responseErrors){
            setErrors(null);
        }
        if(restoreState.state == "not-checked"){
            tryStartPasswordRestore(formValues.email).then(res => {
                if(res.success){
                    showInformation({
                        component: <EmailCodeNotification/>
                    });
                    setRestoreState({
                        state: "email-checked"
                    })
                }else{
                    setErrors(res.data.errors);
                }
            });
        }else if(restoreState.state == "email-checked"){
            if(!formValues.restoreCode){
                throw new Error("Invalid code flow, on submut restore code could not be null")
            }
            validatePasswordRestoreCode(formValues.email, formValues.restoreCode).then(res => {
                if(res.success){
                    setRestoreState({
                        state: "restoreCode-checked",
                    })
                }else{
                    setErrors(res.data.errors);
                }
            });
        }else if(restoreState.state == "restoreCode-checked"){
            restorePassword(
                formValues.password, 
                formValues.restoreCode, 
                formValues.email).then(res => {
                if(res.success){
                    navigate("/login");
                }else{
                    setErrors(res.data.errors);
                }
            })
        }
    }

    const restoreCodeElement = useMemo(() => {
        return <FormControl isRequired={true}>
            <Flex alignItems="center">
                    <FormLabel whiteSpace="nowrap">Restore code:</FormLabel>
                    <Input {...register("restoreCode")} type="text" placeholder="Restore code"/>
            </Flex> 
        </FormControl>
    }, [register]);

    const stateDependentJSX = useMemo(() => {
        const responseErrorsJSX = !!responseErrors? <VStack spacing="1em">
        {responseErrors.map((err, index) =>{
            return <Text fontSize="1em" color="red.500" key={index}>{err}</Text>
        })}
        </VStack>: <></>;

        if(restoreState.state == "not-checked"){
            return <>
                    {responseErrorsJSX}
                    <Button type ="submit" colorScheme="green">Find account</Button>
                   </>
        }else if(restoreState.state == "email-checked"){
            return <> 
                {restoreCodeElement}
                {responseErrorsJSX}
                <Button type ="submit" colorScheme="green">Verify restore code</Button>
                </>;
        }else if(restoreState.state == "restoreCode-checked"){
            return <>
                {restoreCodeElement}
                <FormControl isRequired={true} isInvalid={!!errors.password}>
                    <VStack spacing="1em">
                        <Flex width="100%" alignItems="center">
                            <FormLabel>New Password:</FormLabel>
                            <Input {...register("password", {
                                pattern: {
                                    value: passwordSharedValidation.pattern,
                                    message: passwordSharedValidation.errorMessage
                                },
                                })}
                                width="300px"
                                type="password"
                                placeholder="New password"/>
                        </Flex>
                        {!!errors.password && <FormErrorMessage 
                            alignSelf="flex-start"
                            width="400px">
                            {errors.password.message}
                        </FormErrorMessage>}
                    </VStack> 
                </FormControl>
                <FormControl isRequired={true} isInvalid={!!errors.againPassword}>
                    <VStack spacing="1em">
                        <Flex width="100%" alignItems="center">
                            <FormLabel>Password Again:</FormLabel>
                            <Input {...register("againPassword", {
                                validate: (againPasswordValue, formValues) => {
                                    if(againPasswordValue !== formValues.password){
                                        return "Passwords should match";
                                    }
                                    return undefined;
                                }
                            })} 
                                width="300px" 
                                type="password" 
                                placeholder="Password again"/>
                        </Flex>
                        {!!errors.againPassword && <FormErrorMessage width="400px" alignSelf="flex-start">
                            {errors.againPassword.message}
                        </FormErrorMessage>}
                    </VStack>
                    
                </FormControl>
                {responseErrorsJSX}
                <Button colorScheme="green" type="submit">Change password</Button>
            </>
        }
        throw new Error("Unknown state");
    }, [restoreState, responseErrors, errors])
    return <Flex justifyContent="center" width="100%" paddingTop="4em">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VStack spacing="2em" {...defaultFormOutlineStyles} padding="2em" border="1px solid">
                        <Heading fontSize="1.2em">Enter restore data</Heading>
                        <FormControl isRequired={true}>
                            <Flex alignItems="center">
                                <FormLabel>Email:</FormLabel>
                                <Input {...register("email")} width="300px" type="email" placeholder="Your account email"/>
                            </Flex> 
                        </FormControl>
                        {stateDependentJSX}
                    </VStack>
                </form>
           </Flex>;
}