import { axiosInstance } from "../api/axios";
import { ApiResult, ApiSuccessResult } from "../api/types";

export interface ISiteLookApiModel{
    headerMessage: string;
}

export function loadSiteLook(): Promise<ApiResult<ISiteLookApiModel>>{
    return axiosInstance.get("/SiteLook").then(siteLook => {
        return {
            success: true,
            data: siteLook.data as ISiteLookApiModel
        }
    })
}

export function updateSiteLook(model: ISiteLookApiModel): Promise<ApiResult>{
    return axiosInstance.post("/SiteLook", model).then(succ => {
        return {
            success: true,
        } as ApiSuccessResult;
    })
}