import { useEffect, useState } from "react";
import { GetCartContnet, ItemIdToItemsToBuyCount, RemoveItemFromCart, SailItemCart, UpdateCartSailItemCount } from "../../../../cartmanagement/CartManagement";
import { ISailItemActionDescription, SailItemsTable } from "../GoodsPage/components/SailItems/SailItemsTable";
import { ISailItem } from "../GoodsPage/components/SailItems/SailItems";
import { loadSailItemsWtihIds, mapSailItem } from "../../../../sailitemmanagement/SailItemsManagement";
import { SailItemCount } from "../GoodsPage/components/SailItems/SailItemCount";
import { Button, Heading, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useConfirmationModal } from "../../../../stores/confirmationModal";
import { shallow } from "zustand/shallow";
import { isPaymentSelectionResult, usePaymentTypeChooseModal } from "../../../../stores/paymentTypeSelectionModal";
import { IPaymentRequestModel, IPaymentResponseErrorModel, createBalancePayment, createPayment } from "../../../../paymentManagement/PaymentManagement";
import { ApiFaultResult } from "../../../../api/types";
import { IInformationModalData, useInformationModal } from "../../../../stores/informationtModal";
import { PaymentLink } from "../../../../shared/PaymentLink";
import { useIdentityStore } from "../../../../stores/userIdentity";
import { useNavigate } from "react-router-dom";
import { createRouteToLogin } from "../../../Forms/Login";

const paymentLinkModalDataFactory = (paymentUrl: string): IInformationModalData => ({
    headerName: "Your payment link",
    component: <PaymentLink paymentLink={paymentUrl}/>
})
 
type DisplayType = "ShrinkedTable" | "Full";
export function Cart(){
    const [itemToCount, updateItemToCount] = useState<ItemIdToItemsToBuyCount | null>();
    const [sailItems, setSailItems] = useState<ISailItem[] | null>(null);
    const isUserAuthenticated = useIdentityStore(state => state.userAuthState.isUserAuthenticated);
    const navigate = useNavigate();
    const [sailItemToDelete, setSailItemToDelete] = useState<ISailItem | null>(null);
    const showConfirmationModal = useConfirmationModal(modal => modal.show);
    const confirmationModalState = useConfirmationModal(modal => ({result: modal.result, isDisplayed: modal.isDisplayed}), shallow);
    const resetConfirmationResult = useConfirmationModal(model => model.clearResult);
    const showSailItemLink = useInformationModal(state => state.show);
    const [paymentErrors, setPaymentErrors] = useState<string[]>([]);

    const paymentTypeChooseShow = usePaymentTypeChooseModal(modal => modal.show);
    const paymentTypeCleanup = usePaymentTypeChooseModal(modal => modal.clearResult);
    const selectionResult = usePaymentTypeChooseModal(modal=> ({result: modal.result, isDisplayed: modal.isDisplayed}), shallow)
    
    function handleError(res: ApiFaultResult<IPaymentResponseErrorModel>){

            if(res.data.failedIds){
                res.data.failedIds.forEach(failedId => {
                RemoveItemFromCart(failedId)
            });
            }
            setPaymentErrors([res.data.reason]);
            loadSailItems();
    }

    if(!selectionResult.isDisplayed && isPaymentSelectionResult(selectionResult.result) ){
        const requestModel: IPaymentRequestModel = {
            productsToPurchase: Object.entries(itemToCount!).map(([sailItemId, itemsCount]) => {
                return {
                    productId: parseInt(sailItemId),
                    amount: itemsCount
                }
            }), 
            voucher: selectionResult.result.voucher,
            merchant: selectionResult.result.merchant
        };

        if(selectionResult.result.paymentType == "balance"){
            createBalancePayment(requestModel).then(res => {
                if(!res.success){
                    handleError(res);
                }else{
                    sailItems?.forEach(sailItem => {
                        RemoveItemFromCart(sailItem.id)
                    })
                    loadSailItems();
                }
            })    
        }else{
            createPayment(requestModel).then(res => {
                if(res.success){
                    const paymentUrl = res.data.paymentUrl;
                    sailItems?.forEach(sailItem => {
                        RemoveItemFromCart(sailItem.id);
                    })
                    if(paymentUrl !== ""){
                        //part here cover case when you have used 100% discount woucher
                        showSailItemLink(paymentLinkModalDataFactory(paymentUrl));
                    }
                    loadSailItems();
                }else{
                    handleError(res);
                }
            });
        }
        paymentTypeCleanup();
    }

    const purchaseHandler = () => {
        if(!isUserAuthenticated){
            const targetRoute = createRouteToLogin("cart");
            navigate(targetRoute);
        }
        else if(sailItems && itemToCount){
            setPaymentErrors([]);
            const totalEstimatedPrice = sailItems.reduce((total, sailItem) => {
                const countOfItemsToBuy = itemToCount[sailItem.id];
                const currentItemEstimatedPrice = sailItem.price * countOfItemsToBuy;
                return total + currentItemEstimatedPrice;
            }, 0)
            paymentTypeChooseShow(totalEstimatedPrice);
        }
    };

    const loadSailItems = () => {
        const cartItems = GetCartContnet().sailItems;
        updateItemToCount(cartItems);
        const parsedSailItemIds = Object.keys(cartItems).map(sailItemId => parseInt(sailItemId));
        if(parsedSailItemIds.length === 0){
            setSailItems([] as ISailItem[]);
        }
        else{
            loadSailItemsWtihIds(parsedSailItemIds)
                .then(items => {
                    if(items.success){
                        console.log('here');
                        const mappedItems = items.data.map((item) => mapSailItem(item));
                        mappedItems.forEach(item => {
                            UpdateCartSailItemCount(item.id, Math.min(cartItems[item.id], item.availableCount));
                        })
                        clearFromCartNotMapped(mappedItems, parsedSailItemIds);
                        const cartContainedItems = GetCartContnet().sailItems;
                        updateItemToCount(cartContainedItems);
                        setSailItems(mappedItems);
                    }
                });
            }
    };


    if(!confirmationModalState.isDisplayed){
        if(confirmationModalState.result == "confirmed"){
            RemoveItemFromCart(sailItemToDelete!.id)
            loadSailItems();
        }

        if(confirmationModalState.result == "rejected" || confirmationModalState.result == "confirmed"){
            setSailItemToDelete(null);
            resetConfirmationResult();
        }
    }
    

    const deleteSailItemHandler = (sailItem: ISailItem) => {
            setSailItemToDelete(sailItem);
            showConfirmationModal("Confirm deletion of the product from the cart");
    }

    useEffect(() => {
        loadSailItems();
    }, []);

    const updateSailCount = (sailItem: ISailItem, newCount: number) => {

        UpdateCartSailItemCount(sailItem.id, newCount);
        const cartEnties = GetCartContnet().sailItems;
        updateItemToCount(cartEnties);
    };
    const changeCountDefinition: ISailItemActionDescription = {
        actionName: "Items count",
        actionComponent: (sailItem: ISailItem) => {
            const castedItemToCountMap = itemToCount as ItemIdToItemsToBuyCount;
            const cartCount = castedItemToCountMap[sailItem.id];
            return <SailItemCount showText={false} minValue={1} count={cartCount}  updateCount={(newCount) => updateSailCount(sailItem, newCount)} 
                                  maxValue={sailItem.availableCount}/> 
        }
    }

    const deleteCartItemDefinition: ISailItemActionDescription = {
        actionName: "Cart item action",
        actionComponent: (sailItem: ISailItem) => {
            return <Button colorScheme="red" onClick={() => deleteSailItemHandler(sailItem)}><CloseIcon color="whiteAlpha.1000"/></Button>
        }
    }
    if(!!sailItems && !!itemToCount){
        return <Stack spacing="3.5em"  alignItems="center">
                    <Heading margin="2em auto 0 auto">Cart</Heading>
                    {sailItems.length !== 0? 
                        <SailItemsTable 
                            sailItems={sailItems} 
                            actionDescriptions={[changeCountDefinition, deleteCartItemDefinition]}/>:
                        <Text fontSize="1.4em">You haven't added any item to cart</Text>    
                        }

                    {paymentErrors.length > 0 && <Stack direction="column" spacing="1em">
                        {paymentErrors.map((error, index) => 
                            <Text color="red" fontSize="1.4em" key={index}>{error}</Text>)}
                        </Stack>}
                    {sailItems.length !== 0 && <Button margin="0 auto" width="fit-content" onClick={purchaseHandler} colorScheme="whatsapp">Purchase</Button>}
               </Stack>
    }else{
        return <Text>Loading cart</Text>
    }
}


function clearFromCartNotMapped(mappedItems: ISailItem[], parsedSailItemIds: number[]) {
    var mappedItemsSet = new Set(mappedItems.map(sailItem => sailItem.id));
    const notfetchedItemIds = parsedSailItemIds.filter(itemId => !mappedItemsSet.has(itemId));
    notfetchedItemIds.map(item => RemoveItemFromCart(item));
}

