import { Flex,Text, Heading, Stack, Button } from "@chakra-ui/react";
import { ApiResult, ApiSuccessResult, NoType } from "../../../../../api/types";
import { useEffect, useState } from "react";
import { stringifyPrice } from "../../../../../shared/PriceManagement";
import { loadBalance } from "../../../../../balanceMangement/BalanceMangement";
import { useInformationModal } from "../../../../../stores/informationtModal";
import { FormDefinition, GenericForm } from "../../../../Forms/GenericForm";
import { priceValidator } from "../../../../../shared/validators/SharedValidator";
import { useBalanceUpdateModal } from "../../../../../stores/balanceUpdateModal";
import { PaymentLink } from "../../../../../shared/PaymentLink";

export interface IBalanceModel{
    amount: number;
}


export const Balance = () => {
    const [balanceModel, setBalanceModel] = useState<IBalanceModel | null>(null);
    const isModalDisplayed = useBalanceUpdateModal(modal => modal.isDisplayed);
    const showBalanceTopUpModal = useBalanceUpdateModal(modal => modal.show);
    const modalOperationStatus = useBalanceUpdateModal(modal => modal.operationStatus);
    const clearModalStatus = useBalanceUpdateModal(modal => modal.clearOperationStatus);
    const showPaymentLinkModal = useInformationModal(modal => modal.show);

    useEffect(() => {
        if(!isModalDisplayed && modalOperationStatus?.operationStatus == "Confirmed"){
            showPaymentLinkModal({
                headerName: "Balance top up payment link",
                component: <PaymentLink paymentLink={modalOperationStatus.link}/>
            });
            clearModalStatus();
        }
    }, [modalOperationStatus, isModalDisplayed])

    useEffect(() => {
        loadBalance().then(balanceFetchResult => {
            if(balanceFetchResult.success){
                setBalanceModel({
                    amount: balanceFetchResult.data.balance
                })
            }
        })
    }, []);


    if(balanceModel == null){
        return <Heading>Loading balance</Heading>
    }
    return <Stack>
        <Stack  
            padding="1em 3em"
            spacing="1em" 
            borderRadius="1em" 
            width="fit-content" 
            fontSize="1.4em">
            <Flex alignItems="center" >
                <Text>Your balance: </Text>
                <Text marginLeft="0.5em">{stringifyPrice(balanceModel.amount)}</Text>
            </Flex>
            <Button colorScheme="whatsapp" onClick={() => showBalanceTopUpModal()}>Top Up</Button>
        </Stack>
    </Stack>  
};