import { Box, Flex } from "@chakra-ui/react";
import { ApiResult } from "../../api/types";
import { login } from "../../identiyManagement/IdentityManagement";
import { useIdentityStore } from "../../stores/userIdentity";
import { FormDefinition, GenericForm } from "./GenericForm";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { defaultFormOutlineStyles } from "./formStyles";


type LoginModel = {
    email: string,
    password: string
}

const loginDefinition: FormDefinition<LoginModel> = {
    email: {
        type: "email",
        rules: {
            required: true
        },
        label: "Email:",
        placeHolder: "youremail@yourdomain",
        width: "300px"
    },
    password: {
        type: "password",
        rules: {
            required: true,
        },
        label: "Password: ",
        placeHolder: "password",
        width: "300px"
    },
}

const RestorePassword = () => {
    const navigate = useNavigate();
    return <Box width="100%" 
                _hover={{
                    textDecoration: "underline",
                    cursor: "pointer"}} onClick={() => navigate("/restorePassword")}>Restore password</Box>
}


const LoginForm = ({afterSumbitAction, registerNavigate}: {registerNavigate: () => void, afterSumbitAction: () => void}) => GenericForm({
    outlineStyles: {...defaultFormOutlineStyles, width: "100%"},
    formActions: [
    {
        color: "green",
        text: "Login",
        isSubmit: true,
        width: "10vh"
    },
    {
        color: "red",
        text: "Register",
        action: () => {
            registerNavigate();
        },
        width: "10vh"
    }
],
afterSubmitAction: afterSumbitAction,
formLabel: "Login",
formDefinition: loginDefinition,
submitAction: (loginModel) => {
    return login(loginModel).then(res => {
        if(res.success){
            const responseModel = res.data;
            useIdentityStore.getState().login(responseModel.token, responseModel.email);
        }
        return res;
    });
},
additionalBoottomJSX: RestorePassword
}
);

function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
  }






const navigationParameterName = "navigatedFrom";

export function createRouteToLogin(navigatedFrom?: string): string{
    return "/login" + (!!navigatedFrom? `?${navigationParameterName}=` + navigatedFrom: "");
}


export function Login(){
    const navigate = useNavigate();
    const queryParams = useQuery();

    const navigationPath = useMemo(() => {
        if(queryParams.has(navigationParameterName)){
            return "/" + queryParams.get(navigationParameterName);           
        }
        return "/";
    }, [queryParams])

    const loginAfterSubmitAction = useCallback(() => {
        return navigate(navigationPath);
    }, [navigationPath]);
    return <Flex justifyContent="center" width="100%" paddingTop="4em">
            <LoginForm registerNavigate={() => navigate("/register")} afterSumbitAction={loginAfterSubmitAction}></LoginForm>
        </Flex>;
}