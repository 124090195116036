import {create} from "zustand";



type RejectedTopUp= {
    operationStatus: "Rejected"
}

type ConfirmedTopUp = {
    operationStatus: "Confirmed",
    link: string;
}

interface IBalanceUpdateModal{
    isDisplayed: boolean;
    operationStatus: RejectedTopUp | ConfirmedTopUp | null;
    readonly show: () => void;
    readonly hide: (targetPaymentLink: RejectedTopUp | ConfirmedTopUp) => void;
    readonly clearOperationStatus: () => void;
}



export const useBalanceUpdateModal = create<IBalanceUpdateModal>((set, get) => (
    {
        hide: (targetStatus: RejectedTopUp | ConfirmedTopUp) => {
            if(!get().isDisplayed) return;
            set(() => ({
                isDisplayed: false,
                operationStatus: targetStatus
            }));
        },
        isDisplayed: false,
        show: () => {
            if(get().operationStatus){
                get().clearOperationStatus();
            }
            set(() => ({
                isDisplayed: true
            }))
        },
        operationStatus: null,

        clearOperationStatus: () => {
            set(() => ({
                operationStatus: null
            }));
        }
    }
));

