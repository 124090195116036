import { Link, useLocation, useParams } from "react-router-dom";
import { Flex, Image, HStack, Stack, Text, VStack, Button, Box } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { loadSailItemWithId, mapSailItem } from "../../../../sailitemmanagement/SailItemsManagement";
import { ISailItem } from "../GoodsPage/components/SailItems/SailItems";
import { contentOutlineStyles, outlineLightGray } from "../../../../shared/styles/content-outline";
import { useProductPayment } from "../../../../hooks/useProductPayment";

type LocationParams = {
    id: number;
}

export function createDetailedProductPath(): string{
    return "/product/:id";
}
export function createDatailedProductNavigation(sailItemId: number): string {
    return `/product/${sailItemId}`;
}


type Link = {
    navigationPath: string;
    title: string;
}


function createLinks(sailItemName: string, currentNavigation: string): Link[]{
    const defaultLinks: Link[] = [
        {
            title: "Home",
            navigationPath: "/",
        }
    ];
    return [
        ...defaultLinks,
        {
            title: sailItemName,
            navigationPath: currentNavigation
        }
    ];
}

export function DetailedProduct(){
    const location = useLocation();
    const [sailItem, setSailItem] = useState<ISailItem | null>();
    const { id } = useParams() as unknown as LocationParams;
    const {requestDisplay} = useProductPayment();
    useEffect(() => {
        loadSailItemWithId(id).then(sailItemRes => {
            if(sailItemRes.success){
                const sailItemApiData = sailItemRes.data;
                const mappedSailItem = mapSailItem(sailItemApiData); 
                setSailItem(mappedSailItem);
            } 
        });
    }, [id]);

    
    const links = useMemo(() => {
        if(!sailItem){
            return null;
        }
        const links = createLinks(sailItem.name, location.pathname);
        const mappedLinks =  links.map((link, index) => {
            const [slashIndex, linkIndex] = [index * 2 - 1, index * 2];
            const defaultLink = <Link key={linkIndex} to={link.navigationPath}>
                    <Box color="#969696">{link.title}</Box>
                </Link>; 
            if(index == 0){
                return defaultLink;
            }
            return <>
                        <Text color={outlineLightGray} key={slashIndex}>/</Text>
                        {defaultLink}                    
                   </>
        })

        return <HStack 
                        spacing="1em" 
                        borderBottom="0.5px solid"
                        borderColor={outlineLightGray} 
                        paddingBottom="0.5em">
                            {mappedLinks}
                </HStack>
    }, [sailItem, location]);
    useCallback(() => {
        loadSailItemWithId(id);
    }, [id]);

    const addToCartHandler = useCallback(() => {
        if(sailItem){
            requestDisplay({
                productId: sailItem.id,
                type: "AddToCart"
            })
        }
    }, [sailItem, requestDisplay]);
    const buyNowHandler = useCallback(() => {
        if(sailItem){
            requestDisplay({
                productId: sailItem.id,
                type: "BuyNow"
            });
        }
    }, [sailItem, requestDisplay]);

    return <Flex min-height="70vh" width="100%" padding="1em 3em" justifyContent="center">
            {(sailItem === null || links === null)? <Text>Loading...</Text>:
               <Stack {...contentOutlineStyles} padding="2em" width="100%" spacing="1em" height="100%" justifyContent="flex-start">
                    {links}
                    <Text>Buy {sailItem!.name}</Text>
                    <HStack alignItems="flex-start" spacing="1.5em" flexWrap="wrap" width="100%">
                        <VStack justifyContent="center" spacing="1em">
                            <Image height="200px" src={sailItem?.photoUrl} alt="Here should be product photo"></Image>
                            <HStack spacing="1em" justifyContent="center" flexWrap="wrap">
                                <Button fontSize="1em" colorScheme="green" onClick={buyNowHandler}>Buy now</Button>
                            </HStack>
                        </VStack>
                        <VStack alignItems="flex-start" spacing="1.5em">
                            <Box>Description</Box>
                            <Box dangerouslySetInnerHTML={{__html: sailItem!.productDescription}}></Box>
                        </VStack>
                    </HStack>
                    <Text fontSize="1.2em" fontWeight="500" 
                          dangerouslySetInnerHTML={{__html: sailItem!.productDownText}}></Text>
                </Stack>
            }
        </Flex>
}