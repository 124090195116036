import { Flex, Text } from "@chakra-ui/react";
import { getConfiguration } from "../../../../configuration/Configuration";

export function Footer(){
    const baseText = "©2023 BAPSHOP . All rights reserved";
    const siteName = getConfiguration().applicationName;

    return <Flex direction="column" width="100%" padding="5vh 0" backgroundColor="black" alignItems="center" justifyContent="center">
        <Text color="whiteAlpha.900">{siteName}</Text>
        <Text color="whiteAlpha.900">{baseText}</Text>
    </Flex>
}