import { Flex, Stack } from "@chakra-ui/react";
import { Header } from "./components/Header/Header";
import { Outlet } from "react-router-dom";
import { getConfiguration } from "../../configuration/Configuration";
import { Footer } from "./components/Footer/Footer";

export function Main(props: unknown){
    const appName = getConfiguration().applicationName;
    return  <Flex direction="column" justifyContent="space-between" minHeight="100vh">
                    <Stack width="100%" direction="column" paddingInline="6vw" paddingBottom="1em">
                        <Header name={appName}/>
                        <Outlet/>
                    </Stack>
                <Footer/>
            </Flex>
}  
