import axios from "axios";
import { axiosInstance } from "../api/axios";
import { ApiResult, ApiSuccessResult, handleError } from "../api/types";

export interface IIssueCodeForEmailRequestModel{
    email: string  
}

export interface IValidateRestoreCodeRequestModel{
    email: string;
    restoreCode: string;
}


export function validatePasswordRestoreCode(email: string, restoreCode: string): Promise<ApiResult>{
    const requestModel: IValidateRestoreCodeRequestModel = {
        email: email,
        restoreCode: restoreCode
    };
    return handleError(axiosInstance.post("/IdentityManagement/validateRestoreCode", requestModel).then((res) => {
        return {
            success: true,
        } as ApiSuccessResult
    }))
}


export function tryStartPasswordRestore(email: string): Promise<ApiResult>{
    const requestModel : IIssueCodeForEmailRequestModel = {
        email
    };
    return handleError(axiosInstance.post("/IdentityManagement/tryStartPasswordRestore", requestModel).then(() => {
        return {
            success: true
        } as ApiSuccessResult
    }));
}

export interface IRestorePasswordRequestModel
{
    email: string;
    password: string;
    restoreCode: string;
}

export function restorePassword(
     newPassword: string,
     restoreCode: string,
     email: string): Promise<ApiResult>{
    const requestModel: IRestorePasswordRequestModel = {
        password: newPassword,
        restoreCode: restoreCode,
        email: email
    };
    return handleError(axiosInstance.post("/IdentityManagement/restorePassword", requestModel).then(() => {
        return {
            success: true
        } as ApiSuccessResult;
    }));
}