import {Text, Box, HStack, Stack, Flex} from "@chakra-ui/react";
import {useAuthorization} from "../../../../identiyManagement/hooks";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";

interface ILinkDefinition {
    to: string;
    text: string;
    onClick: (text: string) => void
}

const createDefinitions = (linkClick: (text: string) => void) => {
    return [
        {to: "categories", text: "Categories", onClick: linkClick},
        {to: "products", text: "Products", onClick: linkClick},
        {to: "vouchers", text: "Vouchers", onClick: linkClick},
        {to: "displayOptions", text: "Site Display options", onClick: linkClick},
        {to: 'merchants', text: 'Merchants management'}
    ] as ILinkDefinition[];
}


export function Admin() {
    const {isAuthenticated, userRole} = useAuthorization()
    const [activeLink, setLink] = useState<string | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const linkDefinitions = useMemo(() => createDefinitions(setLink), [setLink]);

    useEffect(() => {
        const targetDefinition = linkDefinitions
            .find(definition => location.pathname.endsWith(definition.to));
        if (targetDefinition) {
            setLink(targetDefinition.text);
        }
    }, [location]);


    const selectedStyles = (linkText: string) => {
        if (activeLink == linkText) {
            //selected styles
            return {color: "blackAlpha.900", fontWeight: "600"};
        }
        return {color: "gray.500", fontWeight: "400"};
    }

    let componentContent: JSX.Element;
    if (!isAuthenticated) {
        componentContent = <Navigate to="/"/>
    } else if (userRole == null) {
        componentContent = <h1>Waiting to fetching user data</h1>
    } else if (userRole !== "Admin") {
        componentContent = <Navigate to="/"/>
    } else {
        componentContent = <Stack>
            <Flex marginTop="1em" marginBottom="2em" wrap="wrap">
                {linkDefinitions.map((definition, index, arr) =>
                    <Box marginBottom="1em" marginRight={index < arr.length - 1 ? "0.5em" : "unset"} cursor="pointer"
                         borderRadius="1em"
                         {...selectedStyles(definition.text)}
                         padding="0.8em"
                         backgroundColor="blue.100"
                         key={index}
                         onClick={() => {
                             setLink(definition.text)
                             navigate(definition.to);
                         }}>
                        <Text>{definition.text}</Text>
                    </Box>)}
            </Flex>
            <Outlet/>
        </Stack>
    }

    return componentContent;
}