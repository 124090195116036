import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { GiHamburgerMenu } from "react-icons/gi"
import { RenderedLink } from "../Header"

export const LinksBurderMenu = ({links}: {links: RenderedLink[]}) => {
    return <Menu>
        {({isOpen}) => (
            <>
            <MenuButton isActive={isOpen} as={Button}>
                <GiHamburgerMenu/>
            </MenuButton>
            <MenuList>
                {links.map((link, index) => <MenuItem key={index} onClick={link.clickHandler}>{link.content}</MenuItem>)}
            </MenuList>
            </>
        )}
        
    </Menu>
}
