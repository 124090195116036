import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Stack,
    useDisclosure,
    Select,
    FormControl,
    FormLabel,
    FormErrorMessage
} from "@chakra-ui/react";
import {paymentRejectedConst, usePaymentTypeChooseModal} from "../../stores/paymentTypeSelectionModal";
import {useCallback, useMemo, useState} from "react";
import {FieldErrors, FieldValues, set, useForm} from "react-hook-form";
import {stringifyPrice} from "../../shared/PriceManagement";
import {VoucherFormControl, voucherGenericValidation} from "./VoucherFormControl";
import SelectMerchant from "../../components/Main/components/Cart/SelectMerchant";

const paymentSelectionFieldName = "paymentType";
const voucherFieldName = "voucher";

type ErrorModelFields = "paymentType" | "voucher";

type VoucherApplication =
    {
        voucherDiscount: number,
        currency: "%" | '$'
    };

export type BaseFormControlProps = {
    getValidationState: () => FormControlState,
    registrationData: object
}

export type FormControlState = { status: "valid" } | { status: "invalid", errorMessage: string };

const PaymentChooseFormControl = ({getValidationState, registrationData}: BaseFormControlProps) => {

    const validationState = getValidationState();
    return <FormControl isRequired={true} isInvalid={validationState.status == "invalid"}>
        <Stack spacing="1em">
            <Flex alignItems="center">
                <FormLabel marginRight="1em" fontSize="1.2em">Method</FormLabel>
                <Select {...registrationData}>
                    <option value="wallet">Crypto payment</option>
                    <option value="balance">Balance payment</option>
                </Select>
            </Flex>
            {validationState.status == "invalid" && <FormErrorMessage>
                {validationState.errorMessage}
            </FormErrorMessage>}
        </Stack>
    </FormControl>
}

export function getValidationStateGeneric<TModelErrors extends FieldErrors<FieldValues>>(
    registrationName: keyof TModelErrors,
    errors: TModelErrors) {
    const targetErrors = errors[registrationName];
    if (!targetErrors) {
        return {status: "valid"} as FormControlState;
    }
    return {
        status: "invalid",
        errorMessage: targetErrors.message!
    } as FormControlState

}

export const applyVoucher = (currency: "$" | "%", voucherValue: number, valueToApplyVoucher: number) => {
    if (currency == "$") {
        return Math.max(valueToApplyVoucher - voucherValue, 0);
    }
    return valueToApplyVoucher * (1 - voucherValue / 100);

};

export function PaymentTypeChooseModal() {
    const [merchant, setMerchant] = useState<string>('plisio');
    const {isOpen, onOpen, onClose} = useDisclosure();
    const esitamatedPrice = usePaymentTypeChooseModal(state => state.estimatedPrice!);
    const [voucherApplication, setVoucherApplication] = useState<VoucherApplication | null>(null);
    const {register, handleSubmit, getValues, formState: {errors}, setError, clearErrors} = useForm({
        defaultValues: {
            [paymentSelectionFieldName]: "wallet",
            [voucherFieldName]: ""
        }
    });


    const isDisplayed = usePaymentTypeChooseModal(state => state.isDisplayed);
    const hide = usePaymentTypeChooseModal(state => state.hide)

    const submitForm = () => {
        //we could ignore voucher errors because it's optional field
        const hadErrors = !!errors[voucherFieldName];
        clearErrors(voucherFieldName);
        handleSubmit((val) => {
            hide({
                paymentType: val[paymentSelectionFieldName] == "wallet" ? "wallet" : "balance",
                voucher: hadErrors ? undefined : val[voucherFieldName],
                merchant: val[paymentSelectionFieldName] == "wallet" ? merchant : undefined
            })
            onClose();
        })();
    }


    const runVoucherValidation = useCallback(() => {
        clearErrors(voucherFieldName);
        const voucherValue = getValues()[voucherFieldName];
        return voucherGenericValidation(voucherValue,
            (errorMessage) => {
                setError(voucherFieldName, {message: errorMessage});
            },
            (successModel) => {
                setVoucherApplication({
                    currency: successModel.currency!,
                    voucherDiscount: successModel.discount!
                });
            }
        )
    }, [clearErrors, getValues, setError, setVoucherApplication]);


    const handleClose = useCallback(() => {
        onClose();
        hide(paymentRejectedConst);
    }, []);

    if (!isOpen && isDisplayed) {
        onOpen();

    } else if (isOpen && !isDisplayed) {
        handleClose()
    }


    return <>
        <Modal isOpen={isOpen} size="xl" onClose={handleClose} isCentered>
            <ModalOverlay/>
            <form>
                <ModalContent>

                    <ModalHeader>Choose payment method</ModalHeader>
                    <ModalBody>
                        <Stack whiteSpace="nowrap" alignItems="center" spacing="2em" marginTop="2em" marginBottom="2em">
                            <PaymentChooseFormControl registrationData={{
                                ...register(paymentSelectionFieldName, {
                                    required: true
                                })
                            }}
                                                      getValidationState={() => getValidationStateGeneric(paymentSelectionFieldName, errors)}/>
                            <VoucherFormControl runVoucherValidation={runVoucherValidation}
                                                registrationData={register(voucherFieldName)}
                                                getValidationState={() => getValidationStateGeneric(voucherFieldName, errors)}/>
                            <Text fontSize="1.2em">Estimated price: {stringifyPrice(esitamatedPrice)}</Text>
                            {voucherApplication !== null && <Text fontSize="1.2em ">
                                Aftter voucher application {stringifyPrice(applyVoucher(voucherApplication.currency,
                                voucherApplication.voucherDiscount,
                                esitamatedPrice).toFixed(2))}
                            </Text>}
                            <SelectMerchant setMerchant={setMerchant}/>

                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Flex width="100%" justifyContent="center">
                            <Button marginRight="3em" onClick={submitForm} paddingInline="1.4em"
                                    colorScheme="whatsapp">Buy</Button>
                            <Button colorScheme="red" onClick={handleClose}>Undo</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    </>
}