import { Button, Flex } from "@chakra-ui/react";

import { useCallback, useMemo } from "react";
export interface ICategory{
    id: number;
    name: string;
}

export function CategoryList({categories, selectedCategory, updateSelectCategory}: 
    {   categories: ICategory[], 
        selectedCategory: number,
        updateSelectCategory: (categoryId: number) => void}){
    const setCategoryFilteringOnClick = useCallback((categoryId: ICategory['id']) => {
        updateSelectCategory(categoryId);
    }, []);

    const categroyContent = useMemo(() => {
        return categories.map((category: ICategory, index: number) => {
            const clickCallback = () => setCategoryFilteringOnClick(category.id);
            let computedStyles = {
                'mr': index < categories.length? "1em": "unset",
                "colorScheme":  category.id == selectedCategory? "red": "facebook"
            };   
            return <Button key={index} {...computedStyles} marginBottom="0.5em" onClick={clickCallback}>{category.name}</Button>
    })
    }, [selectedCategory, categories]);

    return <Flex justifyContent="center" width="100%" flexWrap="wrap">
        {categroyContent}
    </Flex>;
}