import {useQuery} from "react-query";
import {GetAllMerchants} from "../../../../services/Merchant/MerchantService";
import {
    Box, Button,
    ButtonGroup,
    HStack,
    RadioProps,
    Stack,
    StackItem,
    useRadio,
    useRadioGroup,
    VStack
} from "@chakra-ui/react";
import React, {useState} from "react";

type SelectMerchantProps = {
    setMerchant: (merchant: string) => void;
}

export default function SelectMerchant({setMerchant}: SelectMerchantProps) {
    const {data, isLoading, isError} = useQuery('allMerchants', GetAllMerchants)
    const [selectedMerchant, setSelectedMerchant] = useState("");

    const handleSelectMerchant = (merchant: string) => {
        setSelectedMerchant(merchant);
        setMerchant(merchant);
    };

    if (isLoading) {
        return (
            <h1>Loading...</h1>
        );
    }

    if (isError) {
        return <h1>Error fetching merchants</h1>;
    }

    return (
        <VStack>
            <h1>Select a payment system:</h1>
            <ButtonGroup>
                {data?.data.result.merchants.filter((el) => el.active).map((value) => (
                    <Button key={value.defaultName} onClick={() => handleSelectMerchant(value.defaultName)}
                            className={selectedMerchant === value.defaultName ? "selected" : ""} colorScheme={selectedMerchant === value.defaultName ? "blue" : "gray"}>
                        {value.visibleName}
                    </Button>
                ))}
            </ButtonGroup>
        </VStack>
    );
}
