import { ApiFaultResult, ApiSuccessResult, ErrorsResult, handleError } from "../api/types"
import { axiosInstance } from "../api/axios";

export interface IIdentityModel
{
    email: string,
    password: string
}


export interface ILoginResponseModel{
    token: string;
    email: string;
}

export type UserRole = "Admin" | "User";

export interface IUserRoleResponse{
    role: UserRole;
}


export function loadAuthenticatedUserRole(): Promise<ApiSuccessResult<IUserRoleResponse> | ApiFaultResult>{
    return handleError(axiosInstance.get("/IdentityManagement/roles").then(resp => {
        const result: ApiSuccessResult<IUserRoleResponse> = {
            success: true,
            data: resp.data as IUserRoleResponse
        };
        return result;
    }));
}

export function registerUser(model: IIdentityModel): Promise<ApiSuccessResult | ApiFaultResult> {
    return handleError(axiosInstance.post("/IdentityManagement/register", model).then(resp => {
        return {
            success: true
        } as ApiSuccessResult;
    }));
    
}


export function login(model: IIdentityModel): Promise<ApiSuccessResult<ILoginResponseModel> | ApiFaultResult> {
    return handleError(axiosInstance.post("/IdentityManagement/authenticate", model).then(resp =>{
        const result: ApiSuccessResult<ILoginResponseModel> = {
            success: true,
            data: resp.data as ILoginResponseModel
        };
        return result;
    }));
}